import React, { Fragment, useState, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Currentweek from "./Currentweek";
import Timetrackingmainhead from "./Time_tracking_MainHead";
import Tablerowdata from "./Tablerowdata";
import "./time_tracking.css";
// import apiService from "./";
import Timetrackingservice from "../../services/TimeTrackingservice/Timetrackingservice";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  format,
  startOfWeek,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
} from "date-fns";

import WHITEDOWNCHEVRON from "../../../assets/images/whitedownchev.svg";

const TimeTracking = () => {
  const getDates = (from, to) => {
    const cFrom = new Date(from);
    const cTo = new Date(to);
    let dateFormat = "dd,MMM";
    let dayFormat = "EEE";
    let daysArr = [];
    let tempDate = cFrom;
    daysArr.push({
      day: format(new Date(tempDate), dayFormat),
      date: format(new Date(tempDate), dateFormat),
      currentweek: getWeek(currentMonth),
    });
    while (tempDate < cTo) {
      tempDate.setUTCDate(tempDate.getUTCDate() + 1);
      daysArr.push({
        day: format(new Date(tempDate), dayFormat),
        date: format(new Date(tempDate), dateFormat),
        currentweek: getWeek(currentMonth),
      });
    }
    return daysArr;
  };

  const [showDetails, setShowDetails] = useState(false);
  const [data, setData] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [PreviousData, setPreviousData] = useState();
  const [currentWeekforcompair, setcurrentWeekforcompair] = useState(
    getWeek(new Date())
  );
  let startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
  let endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 0 });
  const [weekdates, setweekdates] = useState(getDates(startDate, endDate));
  const [isDisabled, setIsDisabled] = useState(false);
  const [showimagePrev, setShowimagePrev] = useState(false);
  const [showimageNext, setShowimageNext] = useState(false);
  const [isDisabledforNext, setIsDisabledforNext] = useState(false);
  const [empId, setEmpId] = useState(sessionStorage.getItem("Emp_id_fetch"));


  useEffect(() => {
    const apiName = process.env.API_NAME;
    // console.log('API Name:', apiName);
    const fetchqemployeeidData = async () => {
      const reqemployeeid = {
        emp_id_in: empId,
        week_number_in: currentWeek,
        year_in: startDate.getFullYear(),
      };

      try {
        const reqemploye = await Timetrackingservice.post_PreviousData(
          reqemployeeid
        );
        setPreviousData(reqemploye);
        // console.log("New user created:", reqemploye);
        // Handle success
      } catch (error) {
        // Handle error
      }
    };
    fetchqemployeeidData();

    let previouscalweek = currentWeekforcompair;
    if (previouscalweek - currentWeek >= 15) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
      setShowimageNext(false);
    }
    if (currentWeek == previouscalweek) {
      setIsDisabledforNext(true);
      setShowimageNext(true);
    } else {
      setIsDisabledforNext(false);
      setShowimageNext(false);
    }
  }, [currentWeek, currentWeekforcompair]);


  //PREVE BUTTON--------------------------

  const changeWeekprevHandle = () => {
    setCurrentMonth(subWeeks(currentMonth, 1));
    setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    let startDate = startOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 0 });
    let endDate = lastDayOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 0 });
    setweekdates(getDates(startDate, endDate));
    const fetchchangeWeekprevData = async () => {
      const reqemployeeid = {
        emp_id_in: empId,
        week_number_in: currentWeek - 1,
        year_in: startDate.getFullYear(),
      };

      try {
        const reqemploye = await Timetrackingservice.post_PreviousData(
          reqemployeeid
        );
        // console.log("reqemploye", reqemploye);
        setPreviousData(reqemploye.res[0]);
        
        // Handle success
      } catch (error) {
        // Handle error
      }
    };
    fetchchangeWeekprevData();

    const Employee_idpost = sessionStorage.getItem("Employee_id");
  };

  //NEXT BUTTON -----------------------------------

  const changeWeeknextHandle = () => {
    setCurrentMonth(addWeeks(currentMonth, 1));
    setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    let startDate = startOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 0 });
    let endDate = lastDayOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 0 });
    setweekdates(getDates(startDate, endDate));

    const fetchqchangeWeekprevData = async () => {
      const reqemployeeid = {
        emp_id_in: empId,
        week_number_in: currentWeek + 1,
        year_in: startDate.getFullYear(),
      };

      try {
        const reqemploye = await Timetrackingservice.post_PreviousData(
          reqemployeeid
        );
        setPreviousData(reqemploye.data);
        reqemploye.data.map();
        // console.log("New user created:", reqemploye);
        
      } catch (error) {
       
      }
    };
    fetchqchangeWeekprevData();

  
    const fetchGetHoliday = async () => {
      const GetHoliday = {
        CODE_in: "HOLIDAYS",
        SUBCODE_in: ".",
        valueDate_in: startDate,
      };

      try {
        const reqemploye = await Timetrackingservice.CurrentWeekHolidays(
          GetHoliday
        );
        setPreviousData(reqemploye.data);

        // console.log("New user created:", reqemploye);
        // Handle success
      } catch (error) {
        // Handle error
      }
    };
    fetchGetHoliday();
  };
 

  return (
    <Fragment>
      <div className="bodydiv">
        <div className="row mybtn">
          <div className="col-lg-4">
            <Timetrackingmainhead />
          </div>
          <div className="col-6" style={{ marginTop: "104px" }}>
            <Currentweek
              onWeekPrevChange={changeWeekprevHandle}
              showimageprev={showimagePrev}
              onWeekNextChange={changeWeeknextHandle}
              disabledon={isDisabled}
              DisabledforNext={isDisabledforNext}
              currentweek={currentWeek}
              currentWeekNumber={currentMonth}
              showimageNext={showimageNext}
            />
            {/* <div className="d-inline tlthour">
            Total Hours : <span className="hour">{weekhr}hrs</span>
          </div> */}
          </div>
        </div>
        <Tablerowdata
          weekDates={weekdates}
          PreviousData={PreviousData}
          setPreviousData={setPreviousData}
          currentweek={currentWeek}
          currentWeekforcompair={currentWeekforcompair}
          currentWeekNumber={currentMonth}
        />
      </div>
    </Fragment>
  );
};

export default TimeTracking;
