import React from 'react'
import { useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";

const Time_tracking_MainHead = () => {
    const navigate = useNavigate();
    const CalenderView_handle = (event) => {
        event.preventDefault();
        if(event.target.value==="month"){
          navigate("/Dashboard/CalenderMonthView");}
          else if(event.target.value==="week"){
              navigate("/Dashboard/TimeTracking")
          }
      }; 
  return (
    <div>
        <div className="mainheading">Time Tracking</div>
        <div className="subheading">Enter your time sheet</div>
        <div className="mybtn">
        
          <select className="d-inline mytimeweekcss">
            <option value="My Time"> My Time</option>
          </select>
          <select className="d-inline mytimeweekcss" onChange={CalenderView_handle} style={{marginLeft:'12px'}}>
            <option value="week"> Week</option>
            <option value="month">Month</option>
          </select>
          </div>
          
    </div>
  )
}

export default Time_tracking_MainHead
