
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect } from "react";
import "./EditLeaveApplication.css";
import { useState } from 'react';
import moment from "moment";
import Timetrackingservice from "../../services/TimeTrackingservice/Timetrackingservice"
import LeavemanagementService from "../../services/LeaveManagementServices/LeavecalenderweekService"

const getsessiondate = (forenoon, afternoon) => {
    if (forenoon === 1 && afternoon === 1) {
        return "Fullday"
    }
    else if (forenoon === 1 && afternoon === 0) {
        return "Forenoon"

    }
    else if (forenoon === 0 && afternoon === 1) {
        return "Afternoon"
    }

}
const EditLeaveApplication = (props) => {
    let employeeid = sessionStorage.getItem("Emp_id_fetch");
    const [leavedetails, setleavedetails] = useState({ ...props.selectedleavehistory, Start_Date: moment(props.selectedleavehistory?.Start_Date).format('YYYY-MM-DD'), End_Date: moment(props.selectedleavehistory?.End_Date).utc().format('YYYY-MM-DD') });
    const [sessionstartDate, setsessionStartDate] = useState(getsessiondate(props.selectedleavehistory?.Start_date_forenoon, props.selectedleavehistory?.start_date_afternoon));
    const [sessionEndDate, setsessionEndDate] = useState(getsessiondate(props.selectedleavehistory?.end_date_forenoon, props.selectedleavehistory?.end_date_afternoon));
    const [LeaveTypearray, setLeaveTypearray] = useState([]);
    const [CountryName, setCountryName] = useState([]);

    useEffect(() => {
        setleavedetails({ ...props.selectedleavehistory, Start_Date: moment(props.selectedleavehistory?.Start_Date).format('YYYY-MM-DD'), End_Date: moment(props.selectedleavehistory?.End_Date).format('YYYY-MM-DD') });
        setsessionStartDate(getsessiondate(props.selectedleavehistory?.Start_date_forenoon, props.selectedleavehistory?.start_date_afternoon));
        setsessionEndDate(getsessiondate(props.selectedleavehistory?.end_date_forenoon, props.selectedleavehistory?.end_date_afternoon));
    }, [JSON.stringify(props.selectedleavehistory)]);

    useEffect(() => {
        const fetchqprojectCountryname = async () => {
            const countryname = {
                CODE_in: 'COUNTRY',
                SUBCODE_in: '.',
            };

            try {
                const projectnameresponse = await Timetrackingservice.getprojectname(
                    countryname
                );
                const country_name = [];
                projectnameresponse.forEach((doc) => {
                    country_name.push({
                        Name: doc.Name,
                    });
                });
                setCountryName(country_name);


                // console.log(projectnameresponse.message);
            } catch (error) {
                console.log(error.message);
            }
        };




        const fetchprojectnameleave = async () => {
            const leavetype = {
                CODE_in: 'LEAVETYPE',
                SUBCODE_in: 'LEAVE',
            };

            try {
                const projectnameresponse = await Timetrackingservice.getprojectname(
                    leavetype
                );
                const Leave_Type = [];
                projectnameresponse.forEach((doc) => {
                    Leave_Type.push({
                           Name: doc.Name,
                    });
                });
                setLeaveTypearray(Leave_Type);


                // console.log(projectnameresponse.message);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchqprojectCountryname();
        fetchprojectnameleave();


    }, []);



   


    let updateleaveHandler = async (event) => {
        event.preventDefault();
        let Start_date_forenoon;
        let start_date_afternoon;
        let end_date_forenoon;
        let end_date_afternoon;

        if (sessionstartDate === "Fullday") {
            Start_date_forenoon = 1;
            start_date_afternoon = 1;
        }
        else if (sessionstartDate === "Forenoon") {
            Start_date_forenoon = 1;
            start_date_afternoon = 0;

        }
        else if (sessionstartDate === "Afternoon") {
            start_date_afternoon = 1;
            Start_date_forenoon = 0;

        }

        if (sessionEndDate === "Fullday") {
            end_date_forenoon = 1;
            end_date_afternoon = 1;
        }
        else if (sessionEndDate === "Forenoon") {
            end_date_forenoon = 1;
            end_date_afternoon = 0;

        }
        else if (sessionEndDate === "Afternoon") {
            end_date_afternoon = 1;
            end_date_forenoon = 0;

        }

        const { country_name, updatedby, Leave_Type, Start_Date, End_Date, Reason_for_leave } = leavedetails;


        const Posthistory = async () => {
            const reqbody = {
                leaveid: props.selectedleavehistory.Autokey,
                emp_id: employeeid,
                country_name,
                updatedby,
                Leave_Type,
                Start_Date,
                Start_date_forenoon,
                start_date_afternoon,
                End_Date,
                end_date_forenoon,
                end_date_afternoon,
                Reason_for_leave,
                createdby: props.selectedleavehistory.createdby
            };

            try {
                const projectnameresponse = await LeavemanagementService.Leavemanagement(
                    reqbody
                );
                props.onHide();

                const fetchleaveHis = async () => {
                    let employeeid = sessionStorage.getItem("Emp_id_fetch");
                    const reqbody = {
                        emp_id: employeeid
                    };
        
                    try {
                        const leave_history = await LeavemanagementService.LeavemanagementHistory(
                            reqbody
                        );
                        props.leaveHistory(leave_history);
        
        
                        // console.log(leave_history.message);
                    } catch (error) {
                        console.log(error.message);
                    }
                };
                fetchleaveHis();
                // console.log(projectnameresponse.message);
            } catch (error) {
                console.log(error.message);
            }
        };
        Posthistory();
    }

    const deleteLeaveHandler = (event) => {

        const postDeletedata = async () => {
            event.preventDefault();
            const reqdelete = {
                Autokey: props.selectedleavehistory?.Autokey
    
            };

            try {
                const deleterecord= await LeavemanagementService.DeleteLeave(
                    reqdelete
                );
                props.onHide();
               
                const fetchdeletehistory = async () => {
                    
                    let reqbody = {
                        emp_id: employeeid
                    };
        
                    try {
                        const deleterecordhistory= await LeavemanagementService.LeavemanagementHistory(
                            JSON.stringify(reqbody)
                        );
                       
                        props.leaveHistory(deleterecordhistory);
                        // console.log(deleterecordhistory.message);
                    } catch (error) {
                        console.log(error.message);
                    }
                };
                fetchdeletehistory();
               

                // console.log(deleterecord.message);
            } catch (error) {
                console.log(error.message);
            }
        };
        postDeletedata();

 
       

    }
    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Edit Leave
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="bodydiv">
                        <div className="row">
                            <div className="col-md-4 mb-3"> <label className="labelpadding" for="validationDefault01">Select Calender</label>
                                <select onChange={(event) => setleavedetails({ ...leavedetails, country_name: event.target.value })} value={leavedetails?.country_name} className="form-control border" >
                                    {CountryName.map((option) => (
                                        <option value={option.Name} key={option.Name} id={option.Name}>
                                            {option.Name}
                                        </option>
                                    ))}

                                </select>
                            </div>
                            <div className="col-md-4 mb-3"> <label className="labelpadding" for="validationDefault02">Apply For</label>
                                <select onChange={(event) => setleavedetails({ ...leavedetails, updatedby: event.target.value })} value={leavedetails?.updatedby} className="form-control border" >
                                    <option value="Self">Self </option>
                                </select></div>
                            <div className="col-md-4 mb-3"> <label className="labelpadding" for="validationDefault02">Leave Type</label>
                                <select onChange={(event) => setleavedetails({ ...leavedetails, Leave_Type: event.target.value })} value={leavedetails?.Leave_Type} className="form-control border" >
                                    {LeaveTypearray.map((option) => (
                                        <option value={option.Name} key={option.Name} id={option.Name}>
                                            {option.Name}
                                        </option>
                                    ))}
                                </select></div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label className="labelpadding" for="validationDefault03">Start Date</label>
                                <input onChange={(event) => setleavedetails({ ...leavedetails, Start_Date: event.target.value })} value={leavedetails?.Start_Date} type="date" className="form-control border" id="validationDefault03" />

                            </div>
                            <div className="col-md-4 mb-3">
                                <label className="labelpadding" for="validationDefault04">Session - Start Date</label>
                                <select onChange={(event) => setsessionStartDate(event.target.value)} value={sessionstartDate} type="text" className="form-control border" id="validationDefault04">
                                    <option value="Fullday">Fullday</option>
                                    <option value="Forenoon">Forenoon</option>
                                    <option value="Afternoon">Afternoon</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-4 mb-3">
                                <label className="labelpadding" for="validationDefault05">End Date</label>
                                <input onChange={(event) => setleavedetails({ ...leavedetails, End_Date: event.target.value })} value={leavedetails?.End_Date} type="date" className="form-control border" id="validationDefault05" />
                            </div>
                            <div className="col-lg-4 mb-3">
                                <label className="labelpadding" for="validationDefault05">Session-End Date</label>
                                <select onChange={(event) => setsessionEndDate(event.target.value)} value={sessionEndDate} type="text" className="form-control border" id="validationDefault04">
                                    <option value="Fullday">Fullday</option>
                                    <option value="Forenoon">Forenoon</option>
                                    <option value="Afternoon">afternoon</option>
                                </select>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col-lg-8 mb-3">
                                <label className="labelpadding" for="validationDefault05">Reason for leave</label>
                                <input onChange={(event) => setleavedetails({ ...leavedetails, Reason_for_leave: event.target.value })} type="text" value={leavedetails?.Reason_for_leave} className="form-control border" id="validationDefault05" />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <div className='flex justify-content-end'>

                        <Button onClick={deleteLeaveHandler} className='btn deletebtn' >Delete Leave</Button>
                        <Button onClick={updateleaveHandler} className='btn updatebtn' >Update</Button>

                    </div>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default EditLeaveApplication;