import React, {
  Fragment,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";
import "./time_tracking.css";
import Timetrackingservice from "../../services/TimeTrackingservice/Timetrackingservice";
import axios from "axios";
import SelectProjectRow from "./SelectProjectRow";
import {
  // format,subMonths,addMonths,startOfWeek,addDays,isSameDay,lastDayOfWeek,
  // getWeek,addWeeks,subWeeks,
  format,
  startOfWeek,
  lastDayOfWeek,
  getWeek,
  addWeeks,
} from "date-fns";
function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      // console.log("Changed props for tablerow data:", changedProps);
    }
    prev.current = props;
  });
}
const Tablerowdata = (props) => {
  useTraceUpdate(props);
  const dateFormat = "MMM dd,yyyy";
  const startDate = format(
    startOfWeek(props.currentWeekNumber, { weekStartsOn: 0 }),
    dateFormat
  );
  const endDate = format(
    lastDayOfWeek(props.currentWeekNumber, { weekStartsOn: 0 }),
    dateFormat
  );
  const endDateforaddproject = lastDayOfWeek(props.currentWeekNumber, {
    weekStartsOn: 0,
  });

  const datetomorrow = new Date(endDateforaddproject);
  datetomorrow.setDate(datetomorrow.getDate() + 1);
  const [projectnamearray, setprojectnamearray] = useState([]);
  const [TotalHours, setTotalHours] = useState({
    Sun: 0,
    Mon: 0,
    Tue: 0,
    Wed: 0,
    Thu: 0,
    Fri: 0,
    Sat: 0,
  });
  useEffect(() => {
    let intialHours = {
      Sun: 0,
      Mon: 0,
      Tue: 0,
      Wed: 0,
      Thu: 0,
      Fri: 0,
      Sat: 0,
    };
    props.PreviousData?.forEach((item) => {
      intialHours = {
        Sun: item.time_worked_Sun + intialHours.Sun,
        Mon: item.time_worked_Mon + intialHours.Mon,
        Tue: item.time_worked_Tue + intialHours.Tue,
        Wed: item.time_worked_Wed + intialHours.Wed,
        Thu: item.time_worked_Thu + intialHours.Thu,
        Fri: item.time_worked_Fri + intialHours.Fri,
        Sat: item.time_worked_Sat + intialHours.Sat,
      };
    });

    setTotalHours(intialHours);
  }, [JSON.stringify(props.PreviousData)]);

  //FETCH POOJECT NAME --------------------
  useEffect(() => {
    const fetchqemployeeidData = async () => {
      const projname = {
        CODE_in: "PROJECTS",
        SUBCODE_in: ".",
      };

      try {
        const projectnameresponse = await Timetrackingservice.getprojectname(
          projname
        );
        const projectnamearrayvalue = [];
        projectnameresponse.forEach((doc) => {
          projectnamearrayvalue.push({
            Name: doc.Name,
            description: doc.description,
          });
        });
        // console.log("projectnamearrayres", projectnameresponse);
        setprojectnamearray(projectnamearrayvalue);

        // Handle success
      } catch (error) {
        // Handle error
      }
    };
    fetchqemployeeidData();
  }, []);

  const GetWorkingHours = () => {
    var hour = new Array();
    for (var i = 0, l = 20.5; i < l; i += 0.5) {
      let h = {
        text: i,
        value: i,
      };
      hour.push(h);
    }
    return hour;
  };

  const [isDisabled, setDisabled] = useState(false);
  const [dropDownHours, setdropDownHours] = useState(GetWorkingHours());
  const [rows, setRows] = useState([]);
  useEffect(() => {
    if (props.PreviousData) {
      setRows(props.PreviousData);
    }
  }, [JSON.stringify(props.PreviousData)]);

  if (endDateforaddproject > datetomorrow) {
    setDisabled(true);
  }

  const [TimetrackmodalcommentView, setTimetrackmodalcommentView] =
    useState(false);

  const timeTrackCommentViewHandler = useCallback((popupDetls) => {
    setTimetrackmodalcommentView(true);
  }, []);
  const handleTableAddRow = () => {
    let employeeid = sessionStorage.getItem("Employee_id");

    const item = {
      emp_id: employeeid,
      project_name: "",
      week_number: props.currentweek,
      time_worked_Sun: 0,
      comments_Sun: "",
      time_worked_Mon: 0,
      comments_Mon: "",
      time_worked_Tue: 0,
      comments_Tue: "",
      time_worked_Wed: 0,
      comments_Wed: "",
      time_worked_Thu: 0,
      comments_Thu: "",
      time_worked_Fri: 0,
      comments_Fri: "",
      time_worked_Sat: 0,
      comments_Sat: "",
    };
    setRows([...rows, item]);
  };

  const handleRemoveSpecificRow = useCallback(
    (projectname) => {
      let employeeid = sessionStorage.getItem("Employee_id");
      
        const fetchPostweekdata = async () => {
       

          const finaldata = {
            emp_id: employeeid,
            project_name: projectname,
            week_number: props.currentweek,
            time_worked_Sun: 0,
            comments_Sun: "",
            time_worked_Mon: 0,
            comments_Mon: "",
            time_worked_Tue: 0,
            comments_Tue: "",
            time_worked_Wed: 0,
            comments_Wed: "",
            time_worked_Thu: 0,
            comments_Thu: "",
            time_worked_Fri: 0,
            comments_Fri: "",
            time_worked_Sat: 0,
            comments_Sat: "",
          };
          // console.log("employeeiddd",employeeid)
          if (
            employeeid !== null &&employeeid !== "null"
            &&
            projectname !== "" &&
            projectname !== "select" &&
            projectname !== undefined
          ) {
          try {
            const projectnameresponse = await Timetrackingservice.Post_weekdata(
              JSON.stringify(finaldata)
            );
            const fetchPreviousweekdata = async () => {
              let startDate = startOfWeek(
                addWeeks(props.currentWeekNumber, 1),
                {
                  weekStartsOn: 0,
                }
              );
              const reqemployeeid = {
                emp_id_in: employeeid,
                week_number_in: props.currentweek,
                year_in: startDate.getFullYear(),
              };

              try {
                const projectnameresponse =
                  await Timetrackingservice.post_PreviousData(reqemployeeid);
                setRows(projectnameresponse);
                // console.log(projectnameresponse.message);
              } catch (error) {
                console.log(error.message);
              }
            };

            fetchPreviousweekdata();
            

            // console.log(projectnameresponse.message);
            
          }

          
           catch (error) {
            console.log(error.message);
          }
         } else {
            alert("please select project");
          
          }
        };

        fetchPostweekdata();
     

   
    },
    [props.currentWeekNumber, props.currentweek]
  );
  return (
    <Fragment>
      <div style={{ marginTop: "41px" }}>
        <div className="row clearfix">
          <div className="col-md-12 column">
            <table style={{ width: "100%" }} className="table" id="tab_logic">
              <thead>
                <tr
                  style={{ backgroundColor: "#F3F3F3", paddingRight: "30px" }}
                >
                  <th style={{ width: "20%" }} className="">
                    {" "}
                    project{" "}
                  </th>

                  {props.weekDates.map((column, index) => (
                    <th style={{ width: "9%" }} key={index}>
                      <div>{column.day}</div>
                      <div className="columnstyle">{column.date}</div>
                    </th>
                  ))}

                  <th> SAVE </th>
                  <th> DELETE </th>
                </tr>
              </thead>
              <tbody>
                {rows.map((item, rowindex) => (
                  <tr key={JSON.stringify(item)}>
                    {/* 1st select project */}

                    <SelectProjectRow
                      //  setRows={setRows}
                      rows={rows}
                      PreviousData={props.PreviousData}
                      rowindex={rowindex}
                      setTotalHours={setTotalHours}
                      // TotalHours={TotalHours}

                      setPreviousData={props.setPreviousData}
                      previousDataProjectDetails={item}
                      currentWeekforcompair={props.currentWeekforcompair}
                      projectnamearray={projectnamearray}
                      startDate={startDate}
                      currentWeekNumber={props.currentWeekNumber}
                      endDate={endDate}
                      dropDownHours={dropDownHours}
                      timeTrackCommentViewHandler={timeTrackCommentViewHandler}
                      handleRemoveSpecificRow={handleRemoveSpecificRow}
                      weekDates={props.weekDates}
                      TotalCalulationHandler={props.TotalCalulationHandler}
                      CurrentWeek={props.currentweek}
                    />
                  </tr>
                ))}
                <tr>
                  <td>
                    <input
                      className="border-0 addproject bg-white"
                      onClick={handleTableAddRow}
                      type="button"
                      value="Add Project +"
                      disabled={isDisabled}
                    />
                  </td>
                </tr>

                <tr className="tableheadstyle">
                  <td className="tlthourone">Total Hours</td>
                  <td className="tlthourvaluestyle">{TotalHours.Sun} hrs</td>
                  <td className="tlthourvaluestyle">{TotalHours.Mon} hrs</td>
                  <td className="tlthourvaluestyle">{TotalHours.Tue} hrs</td>
                  <td className="tlthourvaluestyle">{TotalHours.Wed} hrs</td>
                  <td className="tlthourvaluestyle">{TotalHours.Thu} hrs</td>
                  <td className="tlthourvaluestyle">{TotalHours.Fri} hrs</td>
                  <td className="tlthourvaluestyle">{TotalHours.Sat} hrs</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="holidays">Thursday, 26 : Indian Republic Day</div>

      {/* <div>{props.Currentweek}</div> */}
    </Fragment>
  );
};

export default Tablerowdata;
