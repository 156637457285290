
import axios from "axios";
const base_url=process.env.REACT_APP_BaseUrl;

export const Post_weekdata = async (finaldata) => {
   
  try {

    const headers = {
      "Content-Type": "application/json",
    };
    const response =await axios.post(
      base_url+"Employees/post_weekdata",
      finaldata,
      {
        headers: headers,
        body: finaldata,
      }
    );

    return response.data;
  } catch (error) {
    // console.error("Error while fetching  finaldata data:", error);
    throw error;
  }
};
export const post_PreviousData = async (reqemployeeid) => {
   
  try {
   
    const headers = {
      "Content-Type": "application/json",
    };
    const response =await axios.post(
      base_url+"Employees/post_PreviousData",
      reqemployeeid,
      {
        headers: headers,
        body: reqemployeeid,
      }
    );

    return response.data;
  } catch (error) {
    // console.error("Error while fetching  post_PreviousData data:", error);
    throw error;
  }
};
export const post_emailid = async (postemailid) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      base_url+"Employees/post_emailid",
      postemailid,
      {
        headers: headers,
        body: postemailid,
      }
    );
    return response.data;
  } catch (error) {
    // console.error("Error while fetching post_emailid data:", error);
    throw error;
  }
};
export const CurrentWeekHolidays = async (GetHoliday) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response =  await axios.post(
      base_url+"Employees/getCurrentWeekHolidays",
      GetHoliday,
      {
        headers: headers,
        body: GetHoliday,
      }
    );
    return response.data;
  } catch (error) {
    // console.error("Error while fetching CurrentWeekHolidays data:", error);
    throw error;
  }
};
export const getprojectname = async (getprojectname) => {
   
  try {

    const headers = {
      "Content-Type": "application/json",
    };
    const response =await axios.post(
      base_url+"Employees/getprojectname",
      getprojectname,
      {
        headers: headers,
        body: getprojectname,
      }
    );

    return response.data;
  } catch (error) {
    // console.error("Error while fetching  getprojectname data:", error);
    throw error;
  }
};

const myModule = {
  post_PreviousData,
  post_emailid,CurrentWeekHolidays,getprojectname,Post_weekdata
};

export default myModule;

