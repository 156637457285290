import React, { useReducer, useState, Fragment } from "react";
import RIGHTCHEVRON from "../../../assets/images/rightchevron.svg";
import LEFTCHEVRON from "../../../assets/images/leftchevron.svg";
import LEFTCHEVRONINACTIVE from "../../../assets/images/previous_inactive.svg";
import OvalCopy from "../../../assets/images/OvalCopy.png";
import Timetrackingmainhead from "./Time_tracking_MainHead";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  startOfMonth,
  endOfMonth,
  isSameMonth,
  endOfWeek,
} from "date-fns";
import "./calender_Month_View.css";


interface ChildrenProps {
  onClick(): void;
}
// const ChildrenProps = {
//     onClick: function() {
//       // Function implementation here
//     }
//   };
interface CalendarProps {
  children(opts: ChildrenProps): React.ReactNode;
}

const Calender_Month_View: React.FC<CalendarProps> = ({ children, props }) => {
  const [state, setState] = useState({
    currentMonth: new Date(),
  });

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";

    return (
    //   <div className="header">
    //     <div className="header-chevron --left">
    //       <div className="icon" onClick={prevMonth}>
    //         chevron_left
    //       </div>
    //     </div>
    //     <div className="header-date --center">
    //       <span>{format(state.currentMonth, dateFormat)}</span>
    //     </div>
    //     <div className="header-chevron --right" onClick={nextMonth}>
    //       <div className="icon">chevron_right</div>
    //     </div>
    //   </div>

    <div
    className=" col-4"
    style={{ paddingTop: "68px"}}
  >
    <div className="d-inline-block currentweekstylemonth">
      <div className="curt_monthtime"> Current Month</div>
      <button style={{ cursor: "pointer" }} className="border-0 bg-white">
        <img
          className="hidden  w-auto lg:block CHEVRON"
          onClick={prevMonth}
          src={LEFTCHEVRON}
         
          alt="Your Company"
        />
      </button>

      <span className="currentweeksubmain">
        <span className="currentweeksub">
        {format(state.currentMonth, dateFormat)}
        </span>
      </span>
      <button style={{ cursor: "pointer" }} className="border-0 bg-white">
        <img
          className="hidden  w-auto lg:block rightCHEVRON"
          style={{
            cursor: "pointer",
          }}
          onClick={nextMonth}
          src={RIGHTCHEVRON}
          alt="Your Company"
        />
      </button>
    </div>
  </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "eee";
    const lowerdateformate=dateFormat.toca
    const days = [];

    let startDate = startOfWeek(state.currentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="day-of-week" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className="days">{days}</div>;
  };

  const renderCells = () => {
    const { currentMonth } = state;
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const selected=true;

    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        days.push(
          <div
            className={`day ${!isSameMonth(day, monthStart) ? "disabled" : ""}`}
            key={`${day}`}
            
            data-date={formattedDate}
          >
             <img style={{color: selected ? 'green':'grey'}}
          className="hidden  w-auto lg:block CHEVRON"
          onClick={prevMonth}
          src={OvalCopy}
         
          alt="Your Company"
        />
          </div>
        );
        day = addDays(day, 1);
        // console.log(day,"day")
      }
      rows.push(
        <div className="week" key={`${day}`}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  const nextMonth = () => {
    setState({
      currentMonth: addMonths(state.currentMonth, 1),
    });
  };

  const prevMonth = () => {
    setState({
      currentMonth: subMonths(state.currentMonth, 1),
    });
  };

  const handleClick = () => {
    // console.log("CLICK");
  };

  return (
    <Fragment>
      <div className="row bodydiv" style={{ overflow: "hidden" }}>
        <div className=" col-3">
          <Timetrackingmainhead />
        </div>

        {renderHeader()}    
       
      </div>
<table className="calendar">
    <tr><th> {renderDays()}</th></tr>
    <tr><td> {renderCells()}</td></tr>
</table>
      
    </Fragment>
  );
};

export default Calender_Month_View;
