import { React, Fragment, useEffect, useState,useRef,memo } from "react";
import SelectHourDropDown from "./SelectHourDropDown";
import { ToastContainer, toast } from "react-toastify";
import Timetrackingservice from "../../services/TimeTrackingservice/Timetrackingservice";
import axios from "axios";
import {
  // format,subMonths,addMonths,startOfWeek,addDays,isSameDay,lastDayOfWeek,
  // getWeek,addWeeks,subWeeks,
  format,
  startOfWeek,
  lastDayOfWeek,
  getWeek,
  addWeeks,
} from "date-fns";

import SAVEACTIVE from "../../../assets/images/save_active.svg";
import SAVEINACTIVE from "../../../assets/images/save_inactive.svg";
import DELETEICON from "../../../assets/images/delete.svg";
function useTraceUpdate(props) {
  const prev = useRef(props);
  useEffect(() => {
    const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
      if (prev.current[k] !== v) {
        ps[k] = [prev.current[k], v];
      }
      return ps;
    }, {});
    if (Object.keys(changedProps).length > 0) {
      // console.log('Changed props:', changedProps);
    }
    prev.current = props;
  });
}

const SelectProjectRow = (props) => {
useTraceUpdate(props)

  let emailIdFromGoogle = sessionStorage.getItem("Email_data");
  const empId = sessionStorage.getItem("Emp_id_fetch");
  const [empName, setEmpName] = useState("");
  sessionStorage.setItem("Employee_id", empId);
  const [isDisabled, setIsDisabled] = useState(true);
  const [projectId, setprojectId] = useState("");
  const [rowsstr, setrowsstr] = useState([...props.rows]);
  const [projectname, setprojectname] = useState("");
  const [getnewdeatils, setgetnewdeatils] = useState([]);
  const [DetailsListnew, setDetailsListnew] = useState([]);

  sessionStorage.setItem("EmpName", empName);

  const projectNameSelectHandler = (event) => {
    let projname = props.projectnamearray.filter(
      (item) => item.Name == event.target.value
    );
    setprojectname(event.target.value);
    setprojectId(projname[0].Name);
    // console.log("projname[0].autokey", projname[0].Name);
  };

  useEffect(() => {
    // console.log(
    //   "props.previousDataProjectDetails.project_name",
    //   props.previousDataProjectDetails.project_name
    // );
    if (props.previousDataProjectDetails.project_name) {
      // console.log("full test");
      setprojectname(props.previousDataProjectDetails.project_name);
    }
  }, [JSON.stringify(props.previousDataProjectDetails)]);

  const [id, setid] = useState();

  useEffect(() => {
    // console.log("getnewdeatils", getnewdeatils);

    getnewdeatils.forEach((listdetail) => {
      const newhour = Number(listdetail.slectedhour);
      const rowindex = listdetail.rowindex;
      const selectedday = listdetail.day;

      const totalhrs = rowsstr.reduce((acc, item, index) => {
        if (rowindex === index) {
          acc = acc + newhour;
        } else {
          acc = acc + Number(item[`time_worked_${selectedday}`]);
          // console.log(item[`time_worked_${selectedday}`], "item check");
        }
        return acc;
      }, 0);

      props.setTotalHours((previousdata) => {
        return { ...previousdata, [selectedday]: totalhrs };
      });
// props.setTotalHours({...props.TotalHours,[selectedday]: totalhrs})
//       console.log(totalhrs)
    });
  }, [JSON.stringify(getnewdeatils)]);

  const updateStateHandler = (Daydetails) => {
    const daydetail = {
      ...Daydetails,
      rowindex: props.rowindex,
    };
    // console.warn(Daydetails);

    setIsDisabled(false);
    // console.log("");
    setgetnewdeatils((previousState) => {
      let DetailsList = previousState.filter(
        (item) => item.day !== daydetail.day
      );
      DetailsList.push(daydetail);
      return DetailsList;
    });
    // setrowsstr((previousdata) => {
    //   console.log("previousdata", previousdata);

    //   const newdata = [...previousdata];
    //   newdata[props.rowindex][`time_worked_${daydetail.day}`] =
    //     daydetail.slectedhour;
    //   newdata[props.rowindex].project_name =
    //     projectname || newdata[props.rowindex].project_name;
    //   newdata[props.rowindex][`comments_${daydetail.day}`] = daydetail.comment;

    //   console.log("newdata", newdata);
    //   return newdata;
    // });
    // let dailyHOurs = {
    //   ProjectID: projectId,
    //   Hour: Daydetails.slectedhour,
    //   day: Daydetails.day,
    //   ProjectName: projectname,
    //   Date: Daydetails.date,
    // };
    // props.TotalCalulationHandler(dailyHOurs);
    setid(daydetail.projectid);
    setDetailsListnew(daydetail);
  };
  sessionStorage.setItem("projectname", projectname);

  //POST API FOR PROJECTNAME
  const postResults = (e) => {

    const fetchPostfinaldata = async () => {
       

     const finaldata = {
        emp_id: empId,
        project_name: projectname,
        week_number: props.CurrentWeek,
        time_worked_Sun: props.previousDataProjectDetails.time_worked_Sun,
        comments_Sun: props.previousDataProjectDetails.comments_Sun,
        time_worked_Mon: props.previousDataProjectDetails.time_worked_Mon,
        comments_Mon: props.previousDataProjectDetails.comments_Mon,
        time_worked_Tue: props.previousDataProjectDetails.time_worked_Tue,
        comments_Tue: props.previousDataProjectDetails.comments_Tue,
        time_worked_Wed: props.previousDataProjectDetails.time_worked_Wed,
        comments_Wed: props.previousDataProjectDetails.comments_Wed,
        time_worked_Thu: props.previousDataProjectDetails.time_worked_Thu,
        comments_Thu: props.previousDataProjectDetails.comments_Thu,
        time_worked_Fri: props.previousDataProjectDetails.time_worked_Fri,
        comments_Fri: props.previousDataProjectDetails.comments_Fri,
        time_worked_Sat: props.previousDataProjectDetails.time_worked_Sat,
        comments_Sat: props.previousDataProjectDetails.comments_Sat,
      };
      getnewdeatils.forEach((weekday) => {
        finaldata["time_worked_" + weekday.day] = weekday.slectedhour;
  
        if (weekday.comment !== undefined) {
          finaldata["comments_" + weekday.day] = weekday.comment;
        } else {
          finaldata["comments_" + weekday.day] = "";
        }
      });
      if (
        empId !== null &&empId !== "null"
        &&
        projectname !== "" &&
        projectname !== "select" &&
        projectname !== undefined
      ) {
        setIsDisabled(true);
      try {
        const finaldataresponse = await Timetrackingservice.Post_weekdata(
          JSON.stringify(finaldata)
        );

        let startDate = startOfWeek(addWeeks(props.currentWeekNumber, 1), {
          weekStartsOn: 0,
        });
        let employeeid = sessionStorage.getItem("Employee_id");
        const fetchPreviousData = async () => {
          const reqemployeeid = {
            emp_id_in: employeeid,
            week_number_in: props.CurrentWeek,
            year_in: startDate.getFullYear(),
          };
    
          try {
            const reqemploye = await Timetrackingservice.post_PreviousData(
              reqemployeeid
            );
            props.setPreviousData(reqemploye);
            // console.log("success fetchPreviousData");
            // Handle success
          } catch (error) {
            console.log("Error while fetching  fetchPreviousData data");
          }
        };
        fetchPreviousData();
        

        // console.log(finaldataresponse.message);
        
      }

      
       catch (error) {
        console.log(error.message);
      }
     } else {
      toast.success(
        "You need to login to submit this data. Please Login!! Or Select project",
        {
          position: "top-center",
          autoClose: 7000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        }
      );
      
      }
    };

    fetchPostfinaldata();
    
  };


  return (
    <Fragment>
      <td>
        <select
          value={projectname}
          style={{ webkitappearance: "none" }}
          onChange={projectNameSelectHandler}
          className="form-select border-0"
        >
          <option defaultValue>select </option>
          {props.projectnamearray.map((option) => (
            <option value={option.Name} key={option.Name} id={option.Name}>
              {option.Name}
            </option>
          ))}
        </select>
      </td>
      {props.weekDates.map((column, index) => (
        <td key={column.date}>
          <SelectHourDropDown
            hourSelection={
              getnewdeatils.find((item) => item.day === column.day)?.slectedhour
            }
            commnetsforday={
              props.previousDataProjectDetails["comments_" + column.day]
            }
            timeWorkedHour={
              props.previousDataProjectDetails["time_worked_" + column.day]
            }
            currentWeekforcompair={props.currentWeekforcompair}
            startDate={props.startDate}
            endDate={props.endDate}
            key={index}
            day={column.day}
            date={column.date}
            projectId={projectId}
            currentweek={props.CurrentWeek}
            dropDownHours={props.dropDownHours}
            updateStateHandler={updateStateHandler}
          //  timeTrackCommentViewHandler={timeTrackCommentViewHandler}
          />
        </td>
      ))}
      <td>
        {isDisabled ? (
          <button disabled className="border-0 bg-white">
            <img src={SAVEINACTIVE} alt="Your Company" />
          </button>
        ) : (
          <button
            style={{ cursor: "pointer" }}
            onClick={postResults}
            className="border-0 bg-white"
          >
            <img src={SAVEACTIVE} alt="Your Company" />
          </button>
        )}
      </td>

      <td>
        <img
          style={{ cursor: "pointer" }}
       
          onClick={() => {
            props.handleRemoveSpecificRow(projectname);
          }}
          src={DELETEICON}
          alt="Your Company"
        />
      </td>
      <ToastContainer
        position="top-center"
        autoClose={7000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </Fragment>
  );
};

export default memo(SelectProjectRow);
