import React, { Fragment } from "react";
import Header from "./modules/Header";
import SideNav from "./modules/SideNav";
import Footer from "./modules/Footer";

const Routess = () => {
  return (
    <Fragment>
      <div>
        <Header />
        <SideNav />
        <Footer />
      </div>
    </Fragment>
  );
};

export default Routess;
