import React, { Fragment, useState, useEffect } from "react";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import InputGroup from "react-bootstrap/InputGroup";
import Timetrackcommentview from "./Timetrackcommentview";

import COMMENTINACTIVE from "../../../assets/images/track_details_no_data.svg";
import WORKINGBLUE from "../../../assets/images/workingdayblue.svg";


const SelectHourDropDown = (props) => {
  const [TimetrackmodalcommentView, setTimetrackmodalcommentView] = useState(false);
  const [dayDetails, setDayDetails] = useState("");
  let symbol = " - ";
  const [txtmessage, settxtMessage] = useState("");
  const timeTrackCommentCloseHandler = () => {
    setTimetrackmodalcommentView(false);
  };
  const [showdatahour, setshowdatahour] = useState(true);


  useEffect(() => {
    if (props.currentWeek == props.currentWeekforcompair) {
      setshowdatahour(true);
    } else {
      setshowdatahour(false);
    }
  }, [props.currentWeek, props.currentWeekforcompair]);

  const handleChangemodel = (commentvalue) => {

    settxtMessage(commentvalue);

    let Day = {
      day: props.day,
      date: props.date,
      slectedhour: props.hourSelection || props.timeWorkedHour,
      projectid: props.projectId,
      comment: commentvalue,
      week_period: props.startDate + symbol + props.endDate,
      week_Number: props.currentweek,
    };
    props.updateStateHandler(Day);
    setDayDetails(Day);
    setTimetrackmodalcommentView(false);

  };


  const [noComment, setnoComment] = useState(false);
  useEffect(() => {
    if (props.commnetsforday == '' && txtmessage == '') {
      setnoComment(true);
    }
  }, [props.commnetsforday,txtmessage]);
  const timeTrackCommentViewHandler = () => {
    setTimetrackmodalcommentView(true);
   
  };
  const updateStateHandler = (event) => {

    let Day;
    Day = {
      day: props.day,
      date: props.date,
      slectedhour: event.target.value,
      projectid: props.projectId,
      comment: dayDetails.comment,
      week_period: props.startDate + symbol + props.endDate,
      week_Number: props.currentweek,
    };
    props.updateStateHandler(Day);
    setDayDetails(Day);
  };

  //   useEffect(() => {
  //    console.log("Useeffect in hour",props.timeWorkedHour)
  // console.log("use in hour",props.commnetsforday)
  //     // console.log("props.timeWorkedHour",props.timeWorkedHour)
  //    if(props.timeWorkedHour>=0 || props.commnetsforday){
  //     let Day = {
  //       day: props.day,
  //       date: props.date,
  //       slectedhour: props.timeWorkedHour,
  //       projectid: props.projectId,
  //       comment: props.commnetsforday,
  //       week_period: props.startDate + symbol + props.endDate,
  //       week_Number: props.currentweek,
  //     };
  //     props.updateStateHandler(Day);
  //     // setHourSelection(props.timeWorkedHour)
  //     setDayDetails(Day);
  //   }
  //   },[props.timeWorkedHour,props.commnetsforday])

  return (
    <Fragment>
      <ButtonToolbar className="btninput d-inline">
        <InputGroup key={props.timeWorkedHour}>
          <select
            style={{
              width: "50px",
              cursor: "pointer",
              display: "inline-block",
            }}
            defaultValue={props.timeWorkedHour}
            onChange={updateStateHandler}
          >
            {props.dropDownHours.map((item) => (
              <option
                style={{
                  width: "50px",
                  cursor: "pointer",
                }}
                value={item.text}
                key={item.value}
              >
                {item.value}
              </option>
            ))}
            ;
          </select>
          {noComment ? (
            <img
              className="imgclass d-inline"
              onClick={timeTrackCommentViewHandler}
              src={COMMENTINACTIVE}

              alt="Your Company"
            />
          ) : (
            <img
              className="imgclass d-inline"
              onClick={timeTrackCommentViewHandler}
              src={WORKINGBLUE}
              alt="Your Company"
            />
          )}
        </InputGroup>
      </ButtonToolbar>
      <Timetrackcommentview
        commnetsforday={props.commnetsforday}
        show={TimetrackmodalcommentView} //handleChangemodel={handleChangemodel}
        // onHide={() => setTimetrackmodalcommentView(false)}
        onHide={handleChangemodel}
        timeclosehandler={timeTrackCommentCloseHandler}
      />
    </Fragment>
  );
};

export default SelectHourDropDown;
