import { React, useState, Fragment,useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./time_tracking.css";

const Timetrackcommentview = (props) => {
  //USE STATES
  const [txtmessage, settxtMessage] = useState("");

  //METHODS
  function escapeHtml(text) {
    return text
        // .replace(/&/g, "&amp;")
        // .replace(/</g, "&lt;")
        // .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
  }
  const handleChangemodel = (event) => {

    settxtMessage(escapeHtml(event.target.value)) ;
  };


  const onhandle = () => {
    
    props.onHide(txtmessage) ;
  };
  
  useEffect(() => {
 
 
    
    settxtMessage(props.commnetsforday);
  },[props.commnetsforday])

  return (
    <Fragment>
      <Modal
        {...props}
        onHide={props.timeTrackCommentCloseHandler}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ height: "404px" }}
      >
        <Modal.Header className="modelheader">
          <div className="addtitle">Add details</div>
        </Modal.Header>
        <Modal.Header className="modelheader1">
          <div className="addsubtitle">Enter the task details below</div>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row" >
              <textarea
              
                value={txtmessage}
                id="textAreaExample5"
                onChange={handleChangemodel}
                style={{
                  width: "90%",
                  margin: "auto",
                  color: "rgba(54,54,54,1)",
                  fontfamily: "Poppins-Regular",
                  fontsize: "13px",
                  lineheight: "22px",
                }}
                className="commenttextarea"
                rows="4"
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="footercomment">
          <Button
            className="closebtnsty text"
            style={{ backgroundColor: "#006ECF" }}
            onClick={props.timeclosehandler}>Close</Button>
          <Button
            className="closebtnsty text"
            onClick={onhandle}
            style={{ backgroundColor: "#006ECF" }}>update</Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Timetrackcommentview;
