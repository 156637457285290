import React, { useState, useEffect } from "react";
import "./LeaveApplicationHistory.css";
import viewIcon from "../../../assets/images/viewIcon.svg";
import editInactiveIcon from "../../../assets/images/edit_inactive.svg";
import Table from "react-bootstrap/Table";
import EditLeaveApplication from "./EditLeaveApplication";
import ViewLeaveApplication from "./ViewLeaveApplication";
import LeaveDropdown from "./LeaveDropdown";
import { LeaveBalanceSheet } from "./LeaveBalanceSheet";
import moment from "moment";
import LeavemanagementService from "../../services/LeaveManagementServices/LeavecalenderweekService"



const LeaveApplicationHistory = () => {
  const [modalShowView, setModalShowView] = useState(false);
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [leaveHistory, setleaveHistory] = useState();
  const [selectedleaveHistory, setselectedleaveHistory] = useState();

  const ViewclickHandler = (viewleavehistory) => () => {
    setModalShowView(true);
    setselectedleaveHistory(viewleavehistory);

  };

  const EditclickHandler = (editleavehistory) => () => {
    setModalShowEdit(true);
    setselectedleaveHistory(editleavehistory);
  };

  let employeeid = sessionStorage.getItem("Emp_id_fetch");

  useEffect(() => {


    const fetchleaveHis = async () => {
      let employeeid = sessionStorage.getItem("Emp_id_fetch");
      const reqbody = {
        emp_id: employeeid
      };
      try {
          const leave_history = await LeavemanagementService.LeavemanagementHistory(
            JSON.stringify(reqbody)
          );
          setleaveHistory(leave_history);


          console.log(leave_history.message);
      } catch (error) {
          console.log(error.message);
      }
  };
  fetchleaveHis();


  }, []);
 
  return (
    <div>
      <div>
        <div className="row bd-highlight mb-3 leavetype">
          <div className="col-md-6" >
            <LeaveDropdown />
          </div>
          <div className="col-md-6 margin" >
            <LeaveBalanceSheet />
          </div>

        </div>
        <div className="leavediv">
          <Table hover className="table-responsive tablesty">
            <thead className="tableheadstyle">
              <tr>
                <th>Applied For</th>
                <th>Leave Type</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>No of Days</th>
                <th>View</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {leaveHistory?.map((item, index) => (
                <tr key={index}>
                  <td>{item.updatedby}</td>
                  <td>{item.Leave_Type}</td>
                  <td>{moment((item.Start_Date).toLocaleString()).format('YYYY-MM-DD ')}</td>
                  <td>{moment((item.End_Date).toLocaleString()).format('YYYY-MM-DD ')}</td>
                  <td>{item.number_ofDays}</td>
                  <td>
                    {" "}
                    <img
                      onClick={ViewclickHandler(item)}
                      className="hidden  w-auto lg:block holidaygreen"
                      src={viewIcon}
                      alt="view icon"
                    />
                  </td>
                  <td>
                    {" "}
                    <img
                      onClick={EditclickHandler(item)}
                      className="hidden  w-auto lg:block holidaygreen"
                      src={editInactiveIcon}
                      alt="editInactiveIcon"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <EditLeaveApplication
         leaveHistory={setleaveHistory}
        selectedleavehistory={selectedleaveHistory}
        show={modalShowEdit}
        onHide={() => setModalShowEdit(false)}
      />
      <ViewLeaveApplication
        selectedleavehistory={selectedleaveHistory}
        show={modalShowView}
        onHide={() => setModalShowView(false)}
      />
    </div>
  );
}

export default LeaveApplicationHistory;
