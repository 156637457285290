import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import dislikebtn from "../../../assets/images/dislike.svg";
import likebtn from "../../../assets/images/like.svg";
import "../../EmployeeEngagement/Idea_Submission/ideasubmission.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getIdeaComments } from '../../services/IdeaManagementServices/Ideaservice';
import { postComment } from '../../services/IdeaManagementServices/Ideaservice';
import { postReply } from '../../services/IdeaManagementServices/Ideaservice';
import { getLikeDislikeInfo } from '../../services/IdeaManagementServices/Ideaservice';
import { likeDislikeIdea } from '../../services/IdeaManagementServices/Ideaservice';

const IdeaFull = (props) => {
  const location = useLocation()
  const idea = location.state || {}
  // console.log("From data", idea);
  const myautokey = idea.Autokey;
  // console.log("Autokeyvalue", myautokey);

  const [replyLabel, setReplyLabel] = useState('Your comment');
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyText, setReplyText] = useState('');
  const [activeReply, setActiveReply] = useState(null);
  const [likeStats, setLikeStats] = useState({ likeCount: 0, dislikeCount: 0 });
  const [isLiked, setIsLiked] = useState(false);
  const [isDisliked, setIsDisliked] = useState(false);

  /* Init - Start */
  function initEmpIdDependantValues() {
    if (employee_id) {
      initIdeaLikeDislikeInfo();
    }
  }
  /* Init - End */

  /* User Login - Start */
  const employee_id= sessionStorage.getItem("Emp_id_fetch");
  const employee_name = sessionStorage.getItem("Emp_name_fetch");
  useEffect(initEmpIdDependantValues, [employee_id]);
  /* User Login - End */

  /* Idea Comment - Start */
  const [allcomments, setAllComments] = useState([]);
  const [commentText, setCommentText] = useState('');

  function initIdeaComments() {
    const ideaId = {
      idea_id: idea.Autokey,
      thread_id: idea.thread_id,
    };

    getIdeaComments(ideaId)
      .then((data) => {
        setAllComments(data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  const handlePostComment = (parentThreadId) => {
    const currentDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
    const newComment = {
      emp_id: employee_id,
      idea_id: idea.Autokey,
      thread_id: parentThreadId,
      sequence: 0,
      comment: commentText,
      comment_created_date: currentDate,
      commented_by: employee_name,
    };

    postComment(newComment)
      .then((data) => {
        // Handle successful comment submission
        // console.log("Comment posted successfully");
        // Clear comment text
        setCommentText('');
        toast.success('Comment posted successfully');
        initIdeaComments();
      })
      .catch((error) => {
        // Handle error
        console.log(error.message);
        toast.error('Error posting comment');
      });
  };

  useEffect(initIdeaComments, []);
  /* Idea Comment - End */

  /* Reply to Comment - Start */
  const [sequence, setSequence] = useState('');
  const [thread_id, setThread_id] = useState('');

  const handleReplyClick = (comment) => {
    setActiveReply(comment.comment_id);
    setShowReplyInput(true);
    setThread_id(comment.thread_id);
    setSequence(allcomments.length + 1);
  };

  const handlePostReply = () => {
    // console.log("Reply posted:", replyText);
    const newReply = {
      emp_id: employee_id,
      idea_id: idea.Autokey,
      thread_id: thread_id,
      sequence: sequence,
      comment: replyText,
      comment_created_date: new Date().toISOString(),
      commented_by: employee_name,
    };

    postReply(newReply)
      .then((data) => {
        // Handle successful reply submission
        // console.log("Reply posted successfully");
        // Clear reply text
        setReplyText('');
        setActiveReply(null);
        toast.success('Reply posted successfully');

        // Update the state with the updated comments
        initIdeaComments();
      })
      .catch((error) => {
        // Handle error
        // console.log("myerror", error);
        toast.error('Error replying to comment');
      });
  };

  const handleCancelReply = () => {
    setActiveReply(null);
    setShowReplyInput(false);
  };
  /* Reply to Comment - End */

  /* Like/Dislike Comment - Start */
  function initIdeaLikeDislikeInfo() {
    const ideaId = {
      idea_id: idea.Autokey,
      emp_id: employee_id
    };

    getLikeDislikeInfo(ideaId)
      .then((data) => {
        if (data) {
          const { likeCount, dislikeCount, isLiked, isDisliked } = data;
          setLikeStats({ likeCount, dislikeCount });
          setIsLiked(isLiked);
          setIsDisliked(isDisliked);
        }

        // console.log("yyyyy", data);
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  useEffect(initIdeaLikeDislikeInfo, []);

  const handleLike = () => {
    let likeIncrement = 0;
    let dislikeIncrement = 0;
    let currentlyLiked = isLiked;
    let currentlyDisliked = isDisliked;

    if (!isLiked && !isDisliked) {
      likeIncrement = 1;
      currentlyLiked = true;
    } else if (isLiked && !isDisliked) {
      likeIncrement = -1;
      currentlyLiked = false;
    } else if (!isLiked && isDisliked) {
      likeIncrement = 1;
      dislikeIncrement = -1;
      currentlyLiked = true;
      currentlyDisliked = false;
    }
    setIsLiked(currentlyLiked);
    setIsDisliked(currentlyDisliked);
    setLikeStats((likeStats) => ({
      likeCount: likeStats.likeCount + likeIncrement,
      dislikeCount: likeStats.dislikeCount + dislikeIncrement,
    }));
    handleLikesAndDislike(currentlyLiked, currentlyDisliked);
  };

  const handleDislike = () => {
    let likeIncrement = 0;
    let dislikeIncrement = 0;
    let currentlyLiked = isLiked;
    let currentlyDisliked = isDisliked;

    if (!isDisliked && !isLiked) {
      dislikeIncrement = 1;
      currentlyDisliked = true;
    } else if (isDisliked && !isLiked) {
      dislikeIncrement = -1;
      currentlyDisliked = false;
    } else if (!isDisliked && isLiked) {
      dislikeIncrement = 1;
      likeIncrement = -1;
      currentlyDisliked = true;
      currentlyLiked = false;
    }

    setIsLiked(currentlyLiked);
    setIsDisliked(currentlyDisliked);
    setLikeStats((likeStats) => ({
      likeCount: likeStats.likeCount + likeIncrement,
      dislikeCount: likeStats.dislikeCount + dislikeIncrement,
    }));
    handleLikesAndDislike(currentlyLiked, currentlyDisliked);
  };

  const handleLikesAndDislike = (currentlyLiked, currentlyDisliked) => {
    const newlikedislike = {
      emp_id: employee_id,
      idea_id: idea.Autokey,
      isLiked: currentlyLiked,
      isDisliked: currentlyDisliked,
    };

    likeDislikeIdea(newlikedislike)
      .then((data) => {
        // console.log("Liked");
        // console.log(data);
        // Update the state or perform any other actions based on the response

        // console.log("Like Count", likeStats.likeCount);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  /* Like/Dislike Comment - End */

  return (
    <div>
      <div {...props}>
        <div style={{ display: "flex", marginTop: "30px" }}>
          <div style={{ marginRight: "10px" }}>
            <img src={"https://picsum.photos/id/1/200/300"} style={{ width: "102px", height: "102px", borderRadius: "50px" }} />
          </div>

          <div className='col-12' style={{ display: "flex", flexDirection: "column", marginBottom: "10px" ,paddingLeft:"16px"}}>
            <label className="mylargetext" style={{ marginBottom: "5px" }}>{idea.idea_subject}</label>
            <div style={{ display: "flex", marginBottom: "5px" }}>
              <label className='datefont'>{idea.idea_created_date}</label>
              <div style={{ marginLeft: "10px" }}>
                {idea.campaign_tags.split(',').map(tag => (
                  <label key={tag} className="mytags" style={{ marginRight: "5px" }}>{tag.trim()}</label>
                ))}
              </div>
              <div className="comments"> {allcomments.length} Comments </div>
            </div>
            <div className='row' style={{ display: "flex", alignItems: "center" }}>
              <label><span className="myname" style={{textDecoration:"underline"}}>{idea.emp_name}</span><span className="mynamesub" style={{textDecoration:"none"}} >{idea.emp_designation}</span></label>
        
            </div>
          </div>
        </div>

        <div style={{ display: "flex", alignItems: "center" }} className="ideadescription  row">
          <div className='col idea_descriptionstyle'>{idea.idea_description}</div>
        </div>

        <div className='row' style={{ display: "flex", marginTop: "10px", }}>
          <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
            <div>

              <img src={likebtn} alt="Like" onClick={handleLike} />
              <span className="likebtn" style={{ marginLeft: "6px" }}>Like ({likeStats.likeCount})</span>

            </div>
            <div>

              <img src={dislikebtn} alt="Dislike" onClick={handleDislike} />
              <span className="dislikebtn" style={{ marginLeft: "6px" }}>Dislike ({likeStats.dislikeCount})</span>

            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="commentheading col-md-6" style={{ marginBottom: "60px" }}>
            <div className="pb-3">
              Comments
            </div>
            {allcomments.map((comment) => (
              <div key={comment.comment_id} className="column">
                <div className="col-md-12">
                  <img src={"https://picsum.photos/id/1/200/300"} style={{ width: "71px", height: "71px", borderRadius: "50px", marginRight: "10px" }} />
                  <label className="myname">{comment.Name}</label>
                  <label className="mydateandtime" style={{ marginLeft: "10px" }}>{comment.DateofComment}</label>
                  {/* <label className="newcommentdisplay" style={{  marginLeft: "10px", width: "26px", height: "18px", left: "613px", right: "1031px" }}> New</label>
                 */}
                  {comment.isNew && (
                    <label
                      className="newcommentdisplay"
                      style={{ marginLeft: "10px" }}
                    >
                      New
                    </label>
                  )}
                </div>
                <div>
                  <label className="comments">{comment.Comment}</label>
                </div>
                <div className="flexbox">
                  <button type="button" className="subbtnn" onClick={() => handleReplyClick(comment)}>Reply</button>
                  <div className="replies">
                    {comment.replies.map((reply) => (
                      <div key={reply.reply_id} className="reply">
                        <div className="replyInfo">
                          <img src={"https://picsum.photos/id/1/200/300"} style={{ width: "50px", height: "50px", borderRadius: "50px" }} />
                          <label className="myname" style={{padding:"2%"}}>{reply.emp_name}</label>
                          <label className="mydateandtime" style={{ marginLeft: "10px" }}>{reply.reply_created_date}</label>
                          <label className="newcommentdisplay" style={{ marginLeft: "10px" }}> New</label>
                          <div>
                          </div>
                        </div>
                        <label className="comments">{reply.reply_text}</label>
                        <div>
                          <button type="button" className="subbtnn" onClick={() => handleReplyClick(comment)}>Reply</button>
                        </div>
                        {activeReply === reply.reply_id && (
                          <div className="commentheading col-md-12" style={{ marginBottom: "25px" }}>
                            <div className="row">
                              <div className="commentboxdata col-md-12">
                                <textarea 
                                  rows={4} cols={35} value={replyText} onChange={(e) => setReplyText(e.target.value)} ></textarea>
                              </div>
                              <div className="col-md-12">
                                <button type="submit" className="submit" style={{ marginRight: "10px" }} onClick={handlePostReply}>Post reply </button>
                                <button type="submit" className="submit" onClick={handleCancelReply} > Cancel  </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                {activeReply === comment.comment_id && (
                  <div className="commentheading col-md-12" style={{ marginBottom: "25px" }}>
                    <div className="row">
                      <div className="commentboxdata col-md-12">
                        <textarea rows={4} cols={35} value={replyText} onChange={(e) => setReplyText(e.target.value)}></textarea>
                      </div>
                      <div className="col-md-12">
                        <button type="submit" className="submit" style={{ marginRight: '10px' }} onClick={handlePostReply}>Post your reply</button>
                        <button type="submit" className="submit" onClick={handleCancelReply}>Cancel</button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="commentheading col-md-6">
            <div className="pb-3 " style={{ paddingLeft: "10px" }}>
              {replyLabel}
            </div>
            <div className="row">
              <div className=" col-md-12">
                <textarea className='commentboxdata' rows={4} cols={35} value={commentText} onChange={(e) => setCommentText(e.target.value)}></textarea>
              </div>
              <div className="col-md-12">
                <button type="submit" className="submit " style={{ marginTop: "22px" }} onClick={() => handlePostComment(thread_id)}>Post comment</button>
              </div>
            </div>

          </div>
          {/* Comment Input */}



          <ToastContainer />
        </div>
      </div>
    </div>
  )
}

export default IdeaFull; 