
import axios from "axios";
const base_url=process.env.REACT_APP_BaseUrl;

export const FetchEmail_id = async (finaldata) => {
   
  try {
  
    const headers = {
      "Content-Type": "application/json",
    };
    const response =await axios.post(
      base_url+"Employees/post_emailid",
      finaldata,
      {
        headers: headers,
        body: finaldata,
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error while fetching  userdetails data:", error);
    throw error;
  }
};
export const  Logintokenreq = async (token) => {
   
  try {
  
    const headers = {
      "Content-Type": "application/json",
    };
    const response =await axios.post(
      base_url+"Employees/post_token",
      token,
      {
        headers: headers,
        body: token,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching  FetchEmail_id :", error);
    throw error;
  }
};


const myModule = {
    FetchEmail_id,Logintokenreq
 
};

export default myModule;

