import axios from "axios";
const base_url=process.env.REACT_APP_BaseUrl;

export const getIdeas = async () => {
    try {
      const response = await axios.get(base_url+"ideaController/ideas");
      return response.data;
    } catch (error) {
      console.error("Error while fetching ideas:", error);
      throw error;
    }
  };

  export const createIdea = async (ideaData) => {
    try {
      const response = await axios.post(base_url+'ideaController/postcreateIdea', ideaData);
      return response.data;
    } catch (error) {
      console.error("Error while creating idea:", error);
      throw error;
    }
  };

 
export const getIdeaComments = async (ideaId) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };

    const response = await axios.post(base_url+"ideaController/getcomments", ideaId, {
      headers: headers,
    });

    const currentDate = new Date();
    const ideaData = response.data.map((doc) => {
      const commentDate = new Date(doc.comment_created_date);
      const isNew = currentDate.getTime() - commentDate.getTime() < 24 * 60 * 60 * 1000;

      return {
        comment_id: doc.Autokey,
        Name: doc.emp_name,
        Comment: doc.comment,
        DateofComment: doc.comment_created_date,
        thread_id: doc.thread_id,
        replies: doc.replies || [], // Initialize replies as an empty array
        isNew: isNew
      };
    });

    return ideaData;
  } catch (error) {
    console.error("Error while fetching idea comments:", error);
    throw error;
  }
};


export const postComment = async (commentData) => {
    try {
      const response = await axios.post(base_url+"ideaController/postcomments", commentData);
      return response.data;
    } catch (error) {
      console.error("Error while posting comment:", error);
      throw error;
    }
  };

  export const postReply = async (replyData) => {
    try {
      const response = await axios.post(base_url+"ideaController/reply", replyData);
      return response.data;
    } catch (error) {
      console.error("Error while posting reply:", error);
      throw error;
    }
  };

  export const getLikeDislikeInfo = async (ideaId) => {
    try {
      const headers = {
        "Content-Type": "application/json",
      };
  
      const response = await axios.post(base_url+"ideaController/getLikeDislike", ideaId, {
        headers: headers,
      });
  
      return response.data;
    } catch (error) {
      console.error("Error while fetching like/dislike information:", error);
      throw error;
    }
  };

  export const likeDislikeIdea = async (likedislikeData) => {
    try {
      const response = await axios.post(base_url+"ideaController/likedislike", likedislikeData);
      return response.data;
    } catch (error) {
      console.error("Error while liking/disliking idea:", error);
      throw error;
    }
  };