import "./LeaveApplication.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import LeaveDropdown from "./LeaveDropdown";
import { LeaveBalanceSheet } from "./LeaveBalanceSheet";
import DatePicker from 'react-datepicker';
import moment from "moment";
import Timetrackingservice from "../../services/TimeTrackingservice/Timetrackingservice";
import commonservice from "../../services/CommonServices/Commonservice";
import leavemanagementservice from "../../services/LeaveManagementServices/LeavecalenderweekService"




const LeaveApplication = () => {
  const notify = () =>
    toast.success("Your leave has been successfully submitted", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
    });
  const [country, setcountry] = useState("INDIA");
  const [applyfor, setapplyfor] = useState("self");
  const [leavetype, setleavetype] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [sessionstartDate, setsessionStartDate] = useState("Fullday");
  const [endDate, setendDate] = useState(new Date());
  const [sessionEndDate, setsessionEndDate] = useState("Fullday");
  const [reasonforleave, setreasonforleave] = useState("");
  const [leaveDetails, setleaveDetails] = useState("");
  const [holidays, setHolidays] = useState([]);
  const [currentmonthLeaves, setcurrentmonthLeaves] = useState([]);
  const [empId, setEmpId] = useState("");
  const [LeaveTypearray, setLeaveTypearray] = useState([]);
  const [CountryName, setCountryName] = useState([]);
  const [formValidcheck, setformValidcheck] = useState(false);
  const [calculatedleaves, setcalculatedleaves] = useState([0]);

  const startdate = new Date(startDate);
  const enddate = new Date(endDate);
  const startyear = startdate.getFullYear();
  const startmonth = startdate.getMonth() + 1;
  const startday = startdate.getDate();
  const year = enddate.getFullYear();
  const month = enddate.getMonth() + 1;
  const day = enddate.getDate();
  const formattedstartDate = `${startyear}-${startmonth < 10 ? '0' + startmonth : startmonth}-${startday < 10 ? '0' + startday : startday}`;
  const formattedendDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;

  let emailIdFromGoogle = sessionStorage.getItem("Email_data");
  let employeeid = sessionStorage.getItem("Emp_id_fetch");

  useEffect(() => {

    const fetchEmailidData = async () => {

      const reqemployeeid = {
        email: emailIdFromGoogle,
      };

      try {
        const reqemploye = await commonservice.FetchEmail_id(
          JSON.stringify(reqemployeeid)
        );
        setEmpId(reqemploye.emp_id);
        sessionStorage.setItem("Emp_id_fetch", reqemploye.emp_id);

      } catch (error) {
        console.log(error.message);
      }
    };
    fetchEmailidData();
  }, [emailIdFromGoogle]);





  useEffect(() => {

    let Start_date_forenoon;
    let start_date_afternoon;
    let end_date_forenoon;
    let end_date_afternoon;

    if (sessionstartDate === "Fullday") {
      Start_date_forenoon = 1;
      start_date_afternoon = 1;
    }
    else if (sessionstartDate === "Forenoon") {
      Start_date_forenoon = 1;
      start_date_afternoon = 0;
    }
    else if (sessionstartDate === "Afternoon") {
      start_date_afternoon = 1;
      Start_date_forenoon = 0;


    }

    if (sessionEndDate === "Fullday") {
      end_date_forenoon = 1;
      end_date_afternoon = 1;
    }
    else if (sessionEndDate === "Forenoon") {
      end_date_forenoon = 1;
      end_date_afternoon = 0;


    }
    else if (sessionEndDate === "Afternoon") {
      end_date_afternoon = 1;
      end_date_forenoon = 0;



    }

    const Posthistory = async () => {

      const reqbody = {
        emp_id: employeeid,
      Leave_Type: leavetype,
      Start_Date: formattedstartDate,
      End_Date: formattedendDate,
      Start_date_forenoon,
      start_date_afternoon,
      end_date_forenoon,
      end_date_afternoon,
      };

      try {
        const projectnameresponse = await leavemanagementservice.calculatedleaves(
          JSON.stringify(reqbody)
        );
        setcalculatedleaves(projectnameresponse[1][0]);
        // console.log(projectnameresponse.message);
      } catch (error) {
        console.log(error.message);
      }
    };
    Posthistory();

    
  }, [formattedstartDate, formattedendDate, sessionstartDate, sessionEndDate, leavetype]);



  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  const currentmonth = d.getMonth() + 1;
  const currentmonthname = monthNames[d.getMonth()];


  let handleSubmit = async (event) => {
    setformValidcheck(true);
    event.preventDefault();
    let Start_date_forenoon;
    let start_date_afternoon;
    let end_date_forenoon;
    let end_date_afternoon;

    if (sessionstartDate === "Fullday") {
      Start_date_forenoon = 1;
      start_date_afternoon = 1;
    }
    else if (sessionstartDate === "Forenoon") {
      Start_date_forenoon = 1;
      start_date_afternoon = 0;
    }
    else if (sessionstartDate === "Afternoon") {
      start_date_afternoon = 1;
      Start_date_forenoon = 0;


    }

    if (sessionEndDate === "Fullday") {
      end_date_forenoon = 1;
      end_date_afternoon = 1;
    }
    else if (sessionEndDate === "Forenoon") {
      end_date_forenoon = 1;
      end_date_afternoon = 0;


    }
    else if (sessionEndDate === "Afternoon") {
      end_date_afternoon = 1;
      end_date_forenoon = 0;



    }

    const Posthistory = async () => {

      const reqbody = {
        leaveid: 0,
        emp_id: empId,
        country_name: country,
        updatedby: applyfor,
        Leave_Type: leavetype,
        Start_Date: formattedstartDate,
        End_Date: formattedendDate,
        Start_date_forenoon,
        start_date_afternoon,
        end_date_forenoon,
        end_date_afternoon,
        Reason_for_leave: reasonforleave,
        createdby: empId,
      };

      try {
        const projectnameresponse = await leavemanagementservice.Leavemanagement(
          JSON.stringify(reqbody)
        );
        setleaveDetails(projectnameresponse);
        // console.log(projectnameresponse.message);
      } catch (error) {
        console.log(error.message);
      }
    };
    Posthistory();

    event.target.reset();
  }





  useEffect(() => {

    let employeeid = sessionStorage.getItem("Emp_id_fetch");

    const fetchCurrentMonthHolidays = async () => {
      const reqbody = {
        CODE: "HOLIDAYS",
        SUBCODE: ".",
        VALUEDATEMONTH: currentmonth,
      };

      try {
        const projectnameresponse = await leavemanagementservice.CurrentMonthHolidays(
          JSON.stringify(reqbody)
        );
        setHolidays(projectnameresponse[0]);

        const fetchCurrentMonthLeaves = async () => {

          const monthreqbody = {
            emp_id: employeeid,
            month_in: currentmonth,
          };


          try {
            const projectnameresponse = await leavemanagementservice.CurrentMonthLeaves(
              JSON.stringify(monthreqbody)
            );
            setcurrentmonthLeaves(projectnameresponse[0]);
          } catch (error) {
            console.log(error.message);
          }
        };
        fetchCurrentMonthLeaves();




      } catch (error) {
        console.log(error.message);
      }
    };
    fetchCurrentMonthHolidays();

    const fetchqprojectname = async () => {
      const countryname = {
        CODE_in: 'COUNTRY',
        SUBCODE_in: '.',
      };

      try {
        const projectnameresponse = await Timetrackingservice.getprojectname(
          countryname
        );
        const country_name = [];
        projectnameresponse.forEach((doc) => {
          country_name.push({
            Name: doc.Name,
          });
        });
        setCountryName(country_name);


        console.log(projectnameresponse.message);
      } catch (error) {
        console.log(error.message);
      }
    };

    const fetchprojectnameleave = async () => {
      const leavetype = {
        CODE_in: 'LEAVETYPE',
        SUBCODE_in: 'LEAVE',
      };

      try {
        const projectnameresponse = await Timetrackingservice.getprojectname(
          leavetype
        );
        const Leave_Type = [];
        projectnameresponse.forEach((doc) => {
          Leave_Type.push({
            Name: doc.Name,
          });
        });
        setLeaveTypearray(Leave_Type);


        console.log(projectnameresponse.message);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchqprojectname();
    fetchprojectnameleave();


  }, []);

  return (
    <div>
      <div className="row bd-highlight mb-3  leavetype">
        <div className="col-md-6">
          <LeaveDropdown leavedetails={leaveDetails} />
        </div>
        <div className="col-md-6 margin">
          <LeaveBalanceSheet />
        </div>
      </div>
      <form onSubmit={handleSubmit} className="mt-4">
        <div className="row">
          <div className="col-lg-2 mb-3">
            <label className="labelpadding" for="validationDefault01">
              Select Calender{" "}
              <span className="glyphicon glyphicon-envelope"></span>
            </label>
            <select value={country}

              onChange={(event) => setcountry(event.target.value)}

              className="form-control border"
            >
              <option hidden={true}>select </option>
              {CountryName.map((option) => (
                <option value={option.Name} key={option.Name} id={option.Name}>
                  {option.Name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-2 mb-3">
            <label className="labelpadding" for="validationDefault02">Apply For</label>
            <select defaultValue={applyfor}
              onChange={(event) => setapplyfor(event.target.value)}
              className="form-control border"
            >
              <option hidden={true}>select </option>
              <option value="self">Self </option>
            </select>
          </div>
          <div className="col-lg-2 mb-3">
            <label className="labelpadding" for="validationDefault02">Leave Type</label>
            <select
              onChange={(event) => setleavetype(event.target.value)}
              className="form-control border"
            >
              <option hidden={true}>select </option>
              {LeaveTypearray.map((option) => (
                <option value={option.Name} key={option.Name} id={option.Name}>
                  {option.Name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2">
            <label className="labelpadding" for="validationDefault03">Start Date</label>
            <DatePicker
              className="form-control border"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
            />

          </div>
          <div className="col-lg-2">
            <label className="labelpadding" for="validationDefault04">Session - Start Date</label>
            <select defaultValue={sessionstartDate} onChange={(event) => 
              setsessionStartDate(event.target.value)} className="form-control border">
              <option hidden={true}>select </option>
              <option value="Fullday">Fullday</option>
              <option value="Forenoon">Forenoon</option>
              <option value="Afternoon">afternoon</option>
            </select>

          </div>

          <div className="col-lg-6">
            <div className="row ">
              <div className="Todaydaysdisplay">
                <div className="divider">
                </div>
                <div>
                  <label className="leavecalchead">Total Days</label>
                  <div className="noofdays">

                    {calculatedleaves?.NumberOfDays}

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>


        <div className="row">
          <div className="col-lg-2">
            <label className="labelpadding" for="validationDefault05">End Date</label>
            <DatePicker
              className="form-control border"
              selected={endDate}
              onChange={(date) => setendDate(date)}
              dateFormat="dd/MM/yyyy"
            />

          </div>
          <div className="col-lg-2 mb-3">
            <label className="labelpadding" for="validationDefault05">Session-End Date</label>
            <select defaultValue={sessionEndDate} onChange={(event) =>
               setsessionEndDate(event.target.value)} className="form-control border">
              <option hidden="true">select </option>
              <option value="Fullday">Fullday</option>
              <option value="Forenoon">Forenoon</option>
              <option value="Afternoon">afternoon</option>
            </select>

          </div>


        </div>


        <div className="row">
          <div className="col-lg-4 pb-3 ">
            <label className="labelpadding" for="validationDefault05">Reason for leave</label>
            <br />
            <input
              onChange={(event) => setreasonforleave(event.target.value)}
              type="text"
              className="form-control border"
              id="validationDefault05"
            />
          </div>
        </div>
        <button onClick={notify} type="submit" disabled={formValidcheck} className="submit">
          Submit
        </button>

        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ color: "red" }} className="heading">
              Holidays in {currentmonthname}
            </p>


            {holidays?.map((item, index) => (
              <div key={index}>
                <p style={{ color: "green" }}>{item.Name}</p>
              </div>
            ))}

          </div>
          <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '118px' }}>
            <p className="heading">Your Applied Leaves for {currentmonthname}</p>

            {currentmonthLeaves?.map((item, index) => (
              <div key={index}  >
                <p>{moment(item.Start_Date).format('MMMM DD') + " - " + moment(item.End_Date).format('MMMM DD') + " - " + item.Leave_Type + " - [" + (item.number_ofDays) + " day(s)]"}</p>

              </div>
            ))}
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </form>
    </div>

  );
};

export default LeaveApplication;
