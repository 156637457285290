import React, { Fragment, useEffect, useState } from "react";
import "./Headerstyle.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useNavigate } from "react-router-dom";
import fetchemailid from "./services/CommonServices/Commonservice"
import LOGO from "../assets/images/Adesso_LOGO.png";
import DOWNCHEV from "../assets/images/down chevron.svg";
import NOTIFICATION from "../assets/images/notification copy.svg";
import LOGOUT from "../assets/images/logout sym.svg";
import SEARCH from "../assets/images/search.svg";
import PROFILEPIC from "../assets/images/loginprofilepic.svg";

const Header = () => {
  const [empId, setEmpId] = useState("");
  const [empName, setEmpName] = useState("");
  let emailIdFromGoogle = sessionStorage.getItem("Email_data");
  console.log("emailIdFromGoogle",emailIdFromGoogle)

  useEffect(() => {

    const fetchqemployeeidData = async () => {
      const reqemailid = {
        email: emailIdFromGoogle,
      };

      try {
        const reqemploye = await fetchemailid.FetchEmail_id(
          reqemailid
        );
        setEmpId(reqemploye.emp_id);
        sessionStorage.setItem("Emp_id_fetch", reqemploye.emp_id);
        setEmpName(reqemploye.emp_name);
        sessionStorage.setItem("Emp_name_fetch", reqemploye.emp_name);
        
      } catch (error) {
        // Handle error
      }
    };
    fetchqemployeeidData();
  }, []);

  // Date.prototype.getMonthName = function () {
  //   var monthNames = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "July",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];
  //   return monthNames[this.getMonth()];
  // };


  const getMonthName = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[date.getMonth()];
  };
  const currentDate = new Date();
  const month_Name = getMonthName(currentDate);
// console.log(monthName);
//   var month_Name = new Date().getMonthName();

  const navigate = useNavigate();
  const current = new Date();
  const date = `${current.getDate()} ${month_Name},${current.getFullYear()}`;
  const componentDidUpdate = () => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", function (event) {
      window.history.pushState(null, document.title, window.location.href);
    });
  };
  const logout = () => {
    navigate("/login", { replace: true });
    componentDidUpdate();
  };

  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-white headermain">
        <div className="container-fluid">
          <span className="navbar-brand" >
            {" "}
            <img
              className="hidden w-auto lg:block logo"
              src={LOGO}
              alt="logo"
            />
          </span>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li>
                <span className="input-group-text sinput " id="search-addon">
                  <img src={SEARCH} alt="search icon" />
                  <input
                    type="search"
                    className="rounded border-0 searchinput"
                    placeholder="Search..."
                    aria-describedby="search-addon"
                  />
                </span>
              </li>
            </ul>
            <form className="d-flex">
              <img
                className=" notisty  "
                src={NOTIFICATION}
                alt="notification"
              />
              <div className="vr"></div>
              <Dropdown className="endlang" >
                <Dropdown.Toggle className="border-0  bg-white lang" >
                  <span className="textsty">
                    En
                    <img className="endownchev" src={DOWNCHEV} alt="downchev" />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="#/action-1">FN</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <div className="vr"></div>
              <div className="logindetails">
                Welcome <br />
                <span style={{ fontFamily: "Poppins-Medium" }}>{empName}</span>
                <br />
                {date}
              </div>
              <div className="profilepic">
                <img src={PROFILEPIC} alt="Your profilepic" />
              </div>
              <div className="vr"></div>
              <Dropdown className="endchecv " >
                <Dropdown.Toggle className="bg-transparent border-0">
                  <img src={DOWNCHEV} alt="downchev" />
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={logout}>
                    Logout <img src={LOGOUT} alt="logo" />
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </form>
          </div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Header;
