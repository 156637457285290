
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Routess from "./_router";
import Login from "./modules/Auth/Login";

import { Route, Routes } from "react-router-dom";

const App = () => (
 

    <Routes>
          <Route exact path="" element={<Login/>}/>
          <Route path="/Dashboard/*" element={<Routess/>}/>            
    </Routes>

);

export default App;


