import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import "../Idea_Submission/ideasubmission.css";
import { createIdea } from '../../services/IdeaManagementServices/Ideaservice';



function PostIdeaModal(props) {
  const [subject, setTitle] = useState("");
  const [tags, setTags] = useState("");
  const [description, setBrief] = useState("");
  const [show, setShow] = useState(false);
  const history = useNavigate();


  const handleClose = () => {

    setShow(false);

    props.refreshideas();
  };
  const handleShow = () => setShow(true);

  const handleTitleChange = event => {
    setTitle(event.target.value);
  };

  const handleTagsChange = event => {
    setTags(event.target.value);
  };

  const handleBriefChange = event => {
    setBrief(event.target.value);
  };

  const employee_id= sessionStorage.getItem("Emp_id_fetch");
  const employee_name = sessionStorage.getItem("Emp_name_fetch");
 
  const handleSubmit = event => {
    event.preventDefault();
    let reqbody = {
      emp_id: employee_id,
      idea_subject: subject,
      idea_description: description,
      idea_submittedby: employee_name,
      campaign_tags: tags,
      likes: 0,
      dislikes: 0,
      createdby: employee_name
    };

    createIdea(reqbody)
      .then(response => {
        handleClose();
        setTitle(""); // Reset the state values to empty strings
        setTags("");
        setBrief("");
        toast.success('Idea posted successfully');
        history('/Dashboard/IdeaSubmission');
      })
      .catch(error => {
        console.error(error);
        toast.error('Failed to post idea');
      });
  };

  return (
    <>

      <Button className="postideabutton" onClick={handleShow}>
        Post an Idea
      </Button>
      <Modal show={show} onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="mainheading">Post Idea</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group className="mb-3 lg" controlId="title">
              <Form.Label className="postideasubheadings">Idea Title</Form.Label>
              <Form.Control type="text" className="col-lg-12" value={subject} onChange={handleTitleChange} required />
            </Form.Group>
            <Form.Group className="mb-3 lg" controlId="tags">
              <Form.Label className="postideasubheadings">Tags (separated by comma)</Form.Label>
              <Form.Control type="text" value={tags} onChange={handleTagsChange} />
            </Form.Group>
            <Form.Group className="mb-3 lg" controlId="brief">
              <Form.Label className="postideasubheadings">Brief your Idea</Form.Label>
              <Form.Control as="textarea" rows={5} value={description} onChange={handleBriefChange} required />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="postideabutton" type="submit">
              Post Idea
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ToastContainer />
    </>
  );
}

export default PostIdeaModal;
