import React, { useReducer, useState, Fragment } from "react";
import HALFDAY from "../../../../src/assets/images/halfday.svg";
import FULLDAY from "../../../../src/assets/images/Fullday.png";
import AFTERNOON from "../../../../src/assets/images/afternoonn.svg";
import "./Leave_Calender_Week_View.css";

import {
  format,
  // subMonths,
  // addMonths,
  startOfWeek,
  addDays,
  parseISO,
  isSaturday,
  isSunday,
  isSameDay,
  lastDayOfWeek,
  // getWeek,
  // addWeeks,
  // subWeeks,
  startOfMonth,
  // endOfMonth,
  // isSameMonth,
  // endOfWeek,
} from "date-fns";

const Leave_Calender_Week_View = (props) => {
  const [currentMonth, setCurrentMonth] = useState(parseISO(new Date()));
  // const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  // const [selectedDate, setSelectedDate] = useState(parseISO(new Date()));
  const [modalShowView, setModalShowView] = useState(false);
  const [oevent, setoevent] = useState(false);
  // const [currentmonthview, setcurrentmonthview] = useState({
  //   currentMonth: new Date(),
  // });
  // const [colorchange, setcolorchange] = useState(false);
  // const onDateClickHandle = (day, dayStr) => {
  //   props.setSelectedDate(day);
  // };
  const imageclickHandler = (event) => () => {
    setModalShowView(true);
    setoevent(event)


  };
  const renderDays = () => {
    // const endDate = lastDayOfWeek(props.currentMonth, { weekStartsOn: 0 });
    const dateFormat1 = "dd";

    const dateFormat = "EEE";
    const days = [];
    let startDate = startOfWeek(props.currentMonth, { weekStartsOn: 0 });
    for (let i = 0; i < 7; i++) {
      days.push(
        <div
          className="col cell "
          style={{
            color: "#363636",
            height: "40px",
            borderBottom: "1px solid #D3D3D3",
            borderRight: "1px solid #D3D3D3",
          }}
          key={i}
        >
          <span className="dayscell">
            {format(addDays(startDate, i), dateFormat)}
          </span>
          <span className="daycell" style={{ marginLeft: "67%" }}>
            {format(addDays(startDate, i), dateFormat1)}
          </span>
        </div>
      );
    }
    return <div className="days row rowsty ">{days}</div>;
  };
  const renderCells = () => {
    const startDate = startOfWeek(props.currentMonth, { weekStartsOn: 0 });
    const monthStart = startOfMonth(currentMonth);
    const endDate = lastDayOfWeek(props.currentMonth, { weekStartsOn: 0 });
    const dateFormat = "dd";
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        let classColorNames = "daycalender";
        if (isSaturday(day) || isSunday(day)) {
          classColorNames += " highlighted";
        }
        const cloneDay = day;
        const dateFormatforcloneDay = format(cloneDay, "yyyy-MM-dd");
     
        let filteredEmployee = props.apiResponseWeekData[0]?.filter((data) => {
          const startDate = new Date(data.start_date);
          const endDate = new Date(data.end_date);
          // if(data.Start_date_forenoon=="1" && data.start_date_afternoon=="1"

          //   )
          return startDate <= day && endDate >= day;

          // console.log("filteredEmployee", filteredEmployee);
        });
        

        days.push(
          <div 
            style={{ borderRight: "1px solid #D3D3D3", height: "168.5px",paddingTop:"40px" }}
            className={`col {classColorNames}  ${
              isSameDay(day, new Date())
                ? "today"
                : isSameDay(day, props.selectedDate)
                ? "selected"
                : ""
            }`}
            key={day}
          >
            {/* <span className={classColorNames}>{formattedDate}</span> */}
            {filteredEmployee?.map((column) => (
              <span  >
                <span>{column.Start_date_forenoon==1}</span>
                <span>{column.start_date_afternoon==1}</span>
                <span className="number" >
                 <img  style={{
                      width: "38px",
                      height: "38px",
                     
                      borderRadius: "50%",
                      marginRight: "12px",
                    }} alt="userimage" src={column.emp_photo}></img> <span style={{fontSize:"8px"}}>{column.emp_id}</span>
                </span>
              </span>
            ))}
          </div>
        );

        day = addDays(day, 1);
      }

      rows.push(
        <div className="row rowsty" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div>{rows}</div>;
  };

  return (
    <Fragment>
      <div
        style={{ overflow: "hidden", marginTop: "20px" }}
        className="calendar"
      >
        <div>{renderDays()}</div>
        <div>{renderCells()}</div>
      </div>
      <div className="row rowsty">
        <div className="col-12" style={{ marginTop: "18px" }}>
          {" "}
          <img src={FULLDAY} alt="grey dot" />{" "}
          <label className="proj">fullday</label>
          <img src={HALFDAY} alt="blue dot" />
          <label className="proj">Morning</label>
          <img src={AFTERNOON} alt="blue dot" />
          <label className="proj">Afternoon</label>
        </div>
        
      </div>
    </Fragment>
  );
};

export default Leave_Calender_Week_View;
