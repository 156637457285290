import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import moment from "moment";
const ViewLeaveApplication = (props) => {

    const formattedstartdate = (moment(props.selectedleavehistory?.Start_Date).format('YYYY-MM-DD'));
    const formattedenddate = (moment(props.selectedleavehistory?.End_Date).format('YYYY-MM-DD'));



    let sessionstartdate;
    let sessionenddate;



    if (props.selectedleavehistory?.Start_date_forenoon === 1 && props.selectedleavehistory?.start_date_afternoon === 1) {
        sessionstartdate = "Fullday"
    }
    else if (props.selectedleavehistory?.Start_date_forenoon === 1 && props.selectedleavehistory?.start_date_afternoon === 0) {
        sessionstartdate = "Forenoon"

    }
    else if (props.selectedleavehistory?.Start_date_forenoon === 0 && props.selectedleavehistory?.start_date_afternoon === 1) {
        sessionstartdate = "Afternoon"
    }




    if (props.selectedleavehistory?.end_date_forenoon === 1 && props.selectedleavehistory?.end_date_afternoon === 1) {
        sessionenddate = "Fullday"
    }
    else if (props.selectedleavehistory?.end_date_forenoon === 1 && props.selectedleavehistory?.end_date_afternoon === 0) {
        sessionenddate = "Forenoon"


    }
    else if (props.selectedleavehistory?.end_date_forenoon === 0 && props.selectedleavehistory?.end_date_afternoon === 1) {
        sessionenddate = "Afternoon"
    }

    return (
        <div>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        View Leave Details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="bodydiv">
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label  className="labelpadding"  for="validationDefault01">Select Calender</label>
                                <input className="form-control border" value={props.selectedleavehistory?.country_name} readOnly />

                            </div>
                            <div className="col-md-4 mb-3">
                                <label  className="labelpadding"  for="validationDefault02">Apply For</label>
                                <input className="form-control border" value={props.selectedleavehistory?.updatedby} readOnly />
                            </div>
                            <div className="col-md-4 mb-3">
                                <label  className="labelpadding"  for="validationDefault02">Leave Type</label>
                                <input className="form-control border" value={props.selectedleavehistory?.Leave_Type} readOnly />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label  className="labelpadding"  for="validationDefault03">Start Date</label>
                                <input type="text" readOnly className="form-control border" value={formattedstartdate} id="validationDefault03" />

                            </div>
                            <div className="col-md-4 mb-3">
                                <label  className="labelpadding"  for="validationDefault04">Session - Start Date</label>
                                <input type="text" readOnly className="form-control border" value={sessionstartdate} id="validationDefault04" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-3">
                                <label  className="labelpadding"  for="validationDefault05">End Date</label>
                                <input type="text" readOnly className="form-control border" value={formattedenddate} id="validationDefault05" />
                            </div>
                            <div className="col-lg-4 mb-3">
                                <label  className="labelpadding"  for="validationDefault05">Session - End Date</label>
                                <input type="text" readOnly className="form-control border" value={sessionenddate} id="validationDefault05" />
                            </div>


                        </div>
                        <div className="row">
                            <div className="col-lg-8 pb-3">
                                <label  className="labelpadding"  for="validationDefault05">Reason for leave</label><br></br>
                                <input type="text" readOnly className="form-control border" id="validationDefault05" value={props.selectedleavehistory?.Reason_for_leave} />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>

    )
}

export default ViewLeaveApplication