import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./Leave_Calender_Week_View.css";
import { format } from "date-fns";


const Leave_Applied_PopUp = (props) => {
  // const [dayResponse, setDayResponse] = useState([]);
  // console.log("dayViewResponse", props.dayViewResponse);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = format(date, "MMM dd, yyyy");
    console.log("for", dateString);
    return formattedDate;
  };

  return (
    <div>
      <div style={{ paddingLeft: "52px" }}>
        <Modal

        
          {...props}
          
        //   style={{width:"571px"}}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
         
          <Modal.Header closeButton  style={{ borderBottom: "none",paddingLeft: "52px" }}>
            <Modal.Title
              id="contained-modal-title-vcenter"
               className="col-lg-8 popupdate"
            >
                {props.oevent}
             
            </Modal.Title>

          </Modal.Header>
          <Modal.Body style={{paddingTop:"0px"}}>
          <div style={{ paddingLeft: "38px",paddingBottom: "28px" }} className="onleavetitle">  Colleagues on leave
        </div>
            <form className="bodydivl">
              {/* <div className='row'>
                            
                        {props.dayViewResponse.map((data, index) => (
                            if(props.oevent.emp_id==data.emp_id){
                                 <div key={index}>{data.number_ofDays}</div>
                            }
       
      ))}
                        </div> */}

              <div className="row" style={{ paddingLeft: "56px", }}>
                {props.dayViewResponse.map((data, index) => (
                  // props.oevent.emp_id === data.emp_id ? (

                  <div className="row">
                    <span className="col-9">
                      <img
                        alt="userimage"
                        style={{
                          width: "38px",
                          height: "38px",
                          borderRadius: "50%",
                          marginRight: "12px",
                        }}
                        src={data.emp_photo}
                      ></img>
                      <span>Name<br></br><span className="dt"> {formatDate(data.start_date)} 
                      </span> - 
                      <span className="dte" > {formatDate(data.end_date)} </span></span>
                      
                    </span>
                    <span className="col-3 noof_days" style={{justifyContent:"right"}} key={index}>
                      {data.number_ofDays} Days
                    </span>
                  </div>
                  // ) : null
                ))}
              </div>
            </form>
            <div className="row w-100" style={{ justifyContent: 'end' }} >
            <Button 
              className="leaveappliedclose "
             
              onClick={props.onHide}
            >
              Close
            </Button></div>
          </Modal.Body>
         
         
        </Modal>
      </div>
    </div>
  );
};

export default Leave_Applied_PopUp;
