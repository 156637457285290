import React from "react";
import { format, startOfWeek, lastDayOfWeek } from "date-fns";

import RIGHTCHEVRON from "../../../assets/images/rightchevron.svg";
import LEFTCHEVRON from "../../../assets/images/leftchevron.svg";
import LEFTCHEVRONINACTIVE from "../../../assets/images/previous_inactive.svg";
import RIGHTCHEVRONINACTIVE from "../../../assets/images/next_inactive.svg";

const Currentweek = (props) => {
  const dateFormat = "MMM dd,yyyy";
  const startDate = format(
    startOfWeek(props.currentWeekNumber, { weekStartsOn: 0 }),
    dateFormat
  );
  const endDate = format(
    lastDayOfWeek(props.currentWeekNumber, { weekStartsOn: 0 }),
    dateFormat
  );

  return (
    <div className="header d-inline" style={{marginTop:"10px"}}>
      <div className="d-inline-block currentweek ">
        <div style={{textAlign:"center",fontFamily:"Poppins-Medium",fontSize:"12px"}}> Current week</div>
        <button
          style={{ cursor: "pointer" }}
          disabled={props.disabledon}
          onClick={props.onWeekPrevChange}
          className="border-0 bg-white"
        >
          {props.disabledon ? (
            <img
              className="hidden  w-auto lg:block CHEVRON"
              // style={{ display: props.disabledon ? "none" : "inline" , cursor: "pointer"}}
              src={LEFTCHEVRONINACTIVE}
              alt="Your Company"
            />
          ) : (
            <img
              className="hidden  w-auto lg:block CHEVRON"
              // style={{ display: props.showimageprev ? "inline" : "none" , cursor: "pointer"}}

              src={LEFTCHEVRON}
              alt="Your Company"
            />
          )}
        </button>

        <span className="currentweeksubmain">
          <span className="currentweeksub">{startDate}</span>
          <span className="currentweeksub">-</span>
          <span className="currentweeksub">{endDate}</span>
        </span>
        <button
          style={{ cursor: "pointer" }}
          disabled={props.DisabledforNext}
          onClick={props.onWeekNextChange}
          className="border-0 bg-white"
        >
          <img
            className="hidden  w-auto lg:block rightCHEVRON"
            style={{
              display: props.DisabledforNext ? "none" : "inline",
              cursor: "pointer",
            }}
            src={RIGHTCHEVRON}
            alt="Your Company"
          />
          <img
            className="hidden  w-auto lg:block rightCHEVRON"
            style={{
              display: props.showimageNext ? "inline" : "none",
              cursor: "pointer",
            }}
            src={RIGHTCHEVRONINACTIVE}
            alt="Your Company"
          />
        </button>
      </div>
    </div>
  );
};

export default Currentweek;
