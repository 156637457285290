import axios from "axios";
// import { API_NAME } from '../../../../';
const baseurlleave = process.env.REACT_APP_BaseUrl;

export const getLeaveWeekView = async (reqWeekData) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      baseurlleave + "EmployeeLeave/getLeaveWeekView",
      reqWeekData,
      {
        headers: headers,
        body: reqWeekData,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching  FetchEmail_id data:", error);
    throw error;
  }
};

export const Leavemanagement = async (postLeave) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      baseurlleave + "EmployeeLeave/postleavemanagement",
      postLeave,
      {
        headers: headers,
        body: postLeave,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching  postLeave data:", error);
    throw error;
  }
};

export const CalculatedLeaves = async (CalculatedLeavesReq) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      baseurlleave + "EmployeeLeave/getCalculatedLeaves",
      CalculatedLeavesReq,
      {
        headers: headers,
        body: CalculatedLeavesReq,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching  CurrentMonthLeaves data:", error);
    throw error;
  }
};

export const CurrentMonthLeaves = async (CurrentMonthLeavesReq) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      baseurlleave + "EmployeeLeave/getCurrentMonthLeaves",
      CurrentMonthLeavesReq,
      {
        headers: headers,
        body: CurrentMonthLeavesReq,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching  CurrentMonthLeaves data:", error);
    throw error;
  }
};

export const CurrentMonthHolidays = async (CurrentMonthReq) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      baseurlleave + "EmployeeLeave/getCurrentMonthHolidays",
      CurrentMonthReq,
      {
        headers: headers,
        body: CurrentMonthReq,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching  CurrentMonthHolidays data:", error);
    throw error;
  }
};
export const LeavemanagementHistory = async (managementHistory) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      baseurlleave + "EmployeeLeave/getleavemanagementHistory",
      managementHistory,
      {
        headers: headers,
        body: managementHistory,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching  managementHistory data:", error);
    throw error;
  }
};
export const DashboardData = async (DashboardDataReq) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      baseurlleave + "EmployeeLeave/getDashboardData",
      DashboardDataReq,
      {
        headers: headers,
        body: DashboardDataReq,
      }
    );
    return response.data;
  } catch (error) {
    // console.error("Error while fetching  DashboardData data:", error);
    throw error;
  }
};
export const DeleteLeave = async (leavedelete) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      baseurlleave + "EmployeeLeave/deleteLeave",
      leavedelete,
      {
        headers: headers,
        body: JSON.stringify(leavedelete),
      }
    );
    return response.data;
  } catch (error) {
    // console.error("Error while fetching  DeleteLeave data:", error);
    throw error;
  }
};

export const LeaveMonthView = async (LeaveMonthViewReq) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      baseurlleave + "EmployeeLeave/getLeaveMonthView",
      LeaveMonthViewReq,
      {
        headers: headers,
        body: LeaveMonthViewReq,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching  LeaveMonthViewRes data:", error);
    throw error;
  }
};
export const leaveCalendarDayView = async (leaveCalendarDayViewReq) => {
  try {
    const headers = {
      "Content-Type": "application/json",
    };
    const response = await axios.post(
      baseurlleave + "EmployeeLeave/getleaveCalendarDayView",
      leaveCalendarDayViewReq,
      {
        headers: headers,
        body: leaveCalendarDayViewReq,
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error while fetching  leaveCalendarDayViewRes data:", error);
    throw error;
  }
};

const myModule = {
  getLeaveWeekView,
  Leavemanagement,
  LeavemanagementHistory,
  DeleteLeave,
  CurrentMonthHolidays,
  CurrentMonthLeaves,
  CalculatedLeaves,
  DashboardData,
  LeaveMonthView,
  leaveCalendarDayView,
};

export default myModule;
