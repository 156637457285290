import React, { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./login.css";
import fetchEmailid from "../services/CommonServices/Commonservice";

function Login() {
  const [tokenres, setTokenres] = useState(null);
  const [Ready, setReady] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    setTokenres(params.get("token"));
    setReady(true);

    const fetchqemailid = async () => {
      const reqemailid = {
        token_i: tokenres,
      };

      try {
        const reqemploye = await fetchEmailid.Logintokenreq(reqemailid);
       
        sessionStorage.setItem("Email_data", reqemploye[0][0].emp_email);
        
        navigate("/Dashboard");
      } catch (error) {}
    };
    fetchqemailid();
  }, [tokenres,navigate]);

  return <Fragment></Fragment>;
}

export default Login;
