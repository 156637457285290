import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const LeaveDropdown = (props) => {
  const [leaveDetails, setleaveDetails] = useState();
  const navigate = useNavigate();
  const leaveCalenderHandler=(event)=>{
    event.preventDefault();
    navigate("/Dashboard/leavecalender");
  }
  const leaveHistoryHandler = (event) => {
    event.preventDefault();
    navigate("/Dashboard/LeaveApplicationHistory");
    setleaveDetails(props.leavedetails);
  };

  return (
    <div className="bd-highlight">
      <div className="d-inline">
        <div className="mainheading">Leave Management</div>
        <div className="subheading">Manage your leaves here</div>
      </div>
      
        <Dropdown className="d-inline">
          <Dropdown.Toggle className="mytimeweekcss">
            Apply Leave{" "}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={leaveHistoryHandler}>
              Leave Management History
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <div className="d-inline mx-2">
          <button type="button" onClick={leaveCalenderHandler} className="mytimeweekcss" style={{border:"none",width:"200px"}}>
            Leave Calender
          </button>

         
        </div>
   
    </div>
  );
};

export default LeaveDropdown;
