import React, { Fragment } from "react";
import "./Footer.css"

const Footer = () => {
  return (
    <Fragment>
      <div className="row">
      <footer className="footer col-12 ">
        <div className="text-left p-4">Webster 2023 - Internal tools</div>
      </footer></div>
    </Fragment>
  );
};

export default Footer;
