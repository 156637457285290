import React, { useState, Fragment } from "react";
import {
  format,
  startOfWeek,
  addDays,
  isSaturday,
  isSunday,
  startOfMonth,
  endOfMonth,
  isSameMonth,
  endOfWeek,
} from "date-fns";
import "./leave_Calender.css";
import "./leave_Calender_Month_View.css";
import LeaveAppliedPopUp from "./Leave_Applied_PopUp";
import leaveCalenderService from "../../services/LeaveManagementServices/LeavecalenderweekService";

import HALFDAY from "../../../../src/assets/images/halfday.svg";
import FULLDAY from "../../../../src/assets/images/Fullday.png";
import AFTERNOON from "../../../../src/assets/images/afternoonn.svg";

const Leave_Calender_Month_View = (props) => {
  const [modalShowView, setModalShowView] = useState(false);
  const [oevent, setoevent] = useState("");
  const [dayViewResponse, setDayViewResponse] = useState([]);

  const renderDays = () => {
    const dateFormat = "eee";
    const days = [];

    let startDate = startOfWeek(props.leaveCurrentMonth);

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="day-of-week" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }

    return <div className="days">{days}</div>;
  };

  const renderCells = () => {
    const monthStart = startOfMonth(props.leaveCurrentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const selected = true;

    const dateFormat = "dd";
    const dateFormatMonth = "MM";
    const dateFormatFull = "MMMM dd, yyyy";

    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";
    let formattedDateMonth = "";
    let formattedFullDate = "";

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        formattedDateMonth = format(day, dateFormatMonth);
        formattedFullDate = format(day, dateFormatFull);
        let classColorNames = "daymonth";

        if (isSaturday(day) || isSunday(day)) {
          classColorNames += " highlighted";
        }
        if (!isSameMonth(day, monthStart)) {
          classColorNames += " disabled";
        }
        // let DisableMOnthClr = "daymonth";
        // let dis = "vanish";
        // if (isSameMonth(day, monthStart)) {
        //   DisableMOnthClr += "dis";
        // }
        let filteredEmployee = props.prevApiResponse[0]?.filter((data) => {
          const startDate = new Date(data.start_date);
          const endDate = new Date(data.end_date);
          return startDate <= day && endDate >= day;
        });

        const imageclickHandler = (
          formattedDate,
          formattedDateMonth,
          formattedFullDate
        ) => {
          setModalShowView(true);
          setoevent(formattedFullDate);
          // console.log("formattedFullDate", formattedFullDate);

          const fetchDayViewData = async () => {
            const reqDayView = {
              date_in: formattedDate,
              month_in: formattedDateMonth,
            };

            try {
              const reqEmployee =
                await leaveCalenderService.leaveCalendarDayView(reqDayView);
              setDayViewResponse(reqEmployee[0]);
              // console.log(reqEmployee.message);
            } catch (error) {
              console.log(error.message);
            }
          };

          fetchDayViewData();
        };

        const handleClick =
          (formattedDate, formattedDateMonth, formattedFullDate) => () => {
            imageclickHandler(
              formattedDate,
              formattedDateMonth,
              formattedFullDate
            );
          };

        days.push(
          <span
            onClick={handleClick(
              formattedDate,
              formattedDateMonth,
              formattedFullDate
            )}
            style={{ display: "inline" }}
            className={classColorNames}
            key={`${day}`}
            data-date={formattedDate}
          >
            {filteredEmployee?.map((column, index) => (
              <span key={index} style={{ display: "inline-block" }}>
                <div>
                  {column.Start_date_forenoon === 1 &&
                  column.start_date_afternoon === 1 ? (
                   <img src={FULLDAY} alt="Full Day" />
                  ) : column.Start_date_forenoon === 1 &&
                    column.start_date_afternoon === 0 ? (
                       <img src={AFTERNOON} alt="Afternoon" /> 
                  ) : column.Start_date_forenoon === 0 &&
                    column.start_date_afternoon === 1 ? (
                      <img src={AFTERNOON} alt="Afternoon" /> 
                  ) : null}
                </div>

                {/* <span>{column.Start_date_forenoon == 1}</span>
                <span>{column.start_date_afternoon == 1}</span> */}
                <div style={{ display: "inline-block" }} className="number">
                  <img
                    alt="userimage"
                    style={{
                      width: "38px",
                      height: "38px",
                      borderRadius: "50%",
                      marginRight: "12px",
                    }}
                    src={column.emp_photo}
                  ></img>
                  {/* {column.emp_id} */}
                </div>
              </span>
            ))}
          </span>
        );

        day = addDays(day, 1);
      }
      rows.push(
        <div className="week" key={`${day}`}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  return (
    <Fragment>
      <div className="row" style={{ overflow: "hidden", marginTop: "17px" }}>
        {/* {renderHeader()} */}
      </div>
      <table className="calendermonth">
        <thead>
          <tr>
            <th> {renderDays()}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td> {renderCells()}</td>
          </tr>
        </tbody>
      </table>
      <div className="row" style={{ marginBottom: "20px" }}>
        <div className="col-12" style={{ marginTop: "18px" }}>
          {" "}
          <img src={FULLDAY} alt="grey dot" />{" "}
          <label className="proj">fullday</label>
          <img src={HALFDAY} alt="blue dot" />
          <label className="proj">Morning</label>
          <img src={AFTERNOON} alt="blue dot" />
          <label className="proj">Afternoon</label>
        </div>
      </div>

      <LeaveAppliedPopUp
        style={{}}
        dayViewResponse={dayViewResponse}
        oevent={oevent}
        show={modalShowView}
        onHide={() => setModalShowView(false)}
      />
    </Fragment>
  );
};

export default Leave_Calender_Month_View;
