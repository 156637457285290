import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PostIdeaModal from './PostIdea_new';
import "../../EmployeeEngagement/Idea_Submission/ideasubmission.css"
import { getIdeas } from '../../services/IdeaManagementServices/Ideaservice';



const Idea_Submission = () => {

    const [allpost, setAllPost] = useState([]);

    function initIdeas() {
        getIdeas()
            .then((data) => {
                setAllPost(data);
            })
            .catch((error) => {
                console.log(error.message);
            });
    }

    useEffect(initIdeas, []);

    return (
        <div>
            <div>
                <div className="row bd-highlight mb-3 leavetype">
                    <div className="col-md-6" >
                        <div className="mainheading">Idea Submission</div>
                        <div className="subheading ">Post your awesome ideas here</div>
                        <PostIdeaModal refreshideas={initIdeas} />
                    </div>
                </div>

                <div className="allpostdiv">
                    {allpost.map((idea) => (
                        <div key={idea.Autokey}>
                            <div style={{ display: "flex", marginTop: "30px" }}>
                                <div>
                                    <img alt="picsum" src={"https://picsum.photos/id/1/200/300"} style={{ width: "63px", height: "63px", borderRadius: "50px" }} />
                                </div>
                                <div className="flexbox">
                                    <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                        <label className="myname">{idea.emp_name}</label>
                                        <label className="mydateandtime">{idea.idea_created_date}</label>
                                        <div style={{ display: "flex", gap: "5px" }}>
                                            {idea.campaign_tags.split(',').map(tag => (
                                                <label key={tag} className="mytags">{tag.trim()}</label>
                                            ))}
                                        </div>
                                    </div>
                                    <div>
                                        <label className="mylargetext">{idea.idea_subject}</label>
                                    </div>
                                </div>

                            </div>
                            <div>
                                <div className="truncate">{idea.idea_description}</div>
                                <Link to="/Dashboard/IdeaFull" state={idea} className="readmore">    Read More </Link>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Idea_Submission;
