import React, { Fragment } from "react";
import "../modules/sidenav.css";
import "../index.js";
import SidebarData from "../modules/SidebarData";
import TimeTracking from "./employee/time_tracking/TimeTracking";
import CalenderMonthView from "./employee/time_tracking/Calender_Month_View"
import LeaveApplication from "./employee/leave_management/LeaveApplication";
import IdeaSubmission from "./EmployeeEngagement/Idea_Submission/Idea_submission";
import { Route, Routes } from "react-router-dom";
import LeaveApplicationHistory from "../modules/employee/leave_management/LeaveApplicationHistory";
import IdeaFull from "./EmployeeEngagement/Idea_Submission/IdeaFull";
import Leavecalender from "./employee/leave_management/Leave_Calender"
import TimeTrackingMainHead from "./employee/employee_projects/TimeTracking_MainHead"




const SideNav = () => {
  return (
    <Fragment>
      <div className="main row" style={{ overflow: 'auto', maxHeight: '900px' }}>
        <div className="col-lg-2 ">
          <div className="sidebarr">
            <SidebarData />
          </div>
        </div>

        <div className="container-fluid col-lg-10  " >
          <Routes>

            <Route exact path="/">
              <Route exact path="TimeTracking" element={<TimeTracking />} />
              <Route exact path="CalenderMonthView" element={<CalenderMonthView/>}/>
              <Route exact path="LeaveMangement" element={<LeaveApplication />} />
              <Route exact path="LeaveApplicationHistory" element={<LeaveApplicationHistory />}/>
              <Route exact path="IdeaSubmission" element={<IdeaSubmission />} />
              <Route path="IdeaFull" element={<IdeaFull />}  />
              <Route path="leavecalender/*" element={<Leavecalender />}  />
              {/* <Route path="Projects/*" element={<TimeTrackingMainHead />}  /> */}

            </Route>
          </Routes>
        </div>
      </div>
    </Fragment>
  );
};

export default SideNav;
