import React, { Fragment } from "react";

const Leave_Calender_header = () => {
  return (
    <Fragment>
      <section style={{marginTop:"27px"}}>
      <div  className="mainheading" style={{paddingLeft:"3px"}}>Leave Mangement &gt; Leave Calendar</div>
      <div className="subheading" >View leave calendar</div>
     </section>
    </Fragment>
  );
};

export default Leave_Calender_header;

