import React, { Fragment, useState, useEffect } from "react";
import LeaveCalenderheader from "./Leave_Calender_header";
import { useNavigate } from "react-router-dom";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  addDays,
  isSaturday,
  isSunday,
  isSameDay,
  lastDayOfWeek,
  getWeek,
  addWeeks,
  subWeeks,
  startOfMonth,
  endOfMonth,
  isSameMonth,
  endOfWeek,
} from "date-fns";
import leaveCalenderService from "../../services/LeaveManagementServices/LeavecalenderweekService";
import axios from "axios";
import LeaveCalenderMonthView from "./Leave_Calender_Month_View";
import LeaveCalenderWeekView from "./Leave_Calender_Week_View";
import Currentweek from "../time_tracking/Currentweek";
import { Route, Routes } from "react-router-dom";

import RIGHTCHEVRON from "../../../assets/images/rightchevron.svg";
import LEFTCHEVRON from "../../../assets/images/leftchevron.svg";

const Leave_Calender = () => {
  //GET DATES
  const getDates = (from, to) => {
    const cFrom = new Date(from);
    const cTo = new Date(to);
    let dateFormat = "dd,MMM";
    let dayFormat = "EEE";
    let datedayFormate = "dd";
    let daysArr = [];
    let tempDate = cFrom;
    daysArr.push({
      day: format(new Date(tempDate), dayFormat),
      date: format(new Date(tempDate), dateFormat),

      dateday: format(new Date(tempDate), datedayFormate),
      saturday: isSaturday(format(new Date(tempDate), datedayFormate)),
      sunday: isSunday(format(new Date(tempDate), datedayFormate)),
      currentweek: getWeek(currentMonth),
    });
    while (tempDate < cTo) {
      tempDate.setUTCDate(tempDate.getUTCDate() + 1);
      daysArr.push({
        day: format(new Date(tempDate), dayFormat),
        date: format(new Date(tempDate), dateFormat),
        dateday: format(new Date(tempDate), datedayFormate),
        saturday: isSaturday(format(new Date(tempDate), datedayFormate)),
        sunday: isSunday(format(new Date(tempDate), datedayFormate)),
        currentweek: getWeek(currentMonth),
      });
    }
    return daysArr;
  };
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [currentWeek, setCurrentWeek] = useState(getWeek(currentMonth));
  let startDate = startOfWeek(currentMonth, { weekStartsOn: 0 });
  let endDate = lastDayOfWeek(currentMonth, { weekStartsOn: 0 });
  const [isDisabled, setIsDisabled] = useState(false);
  const [showimagePrev, setShowimagePrev] = useState(false);
  const [showimageNext, setShowimageNext] = useState(false);
  const [isDisabledforNext, setIsDisabledforNext] = useState(false);
  const [isButtonSelected, setIsButtonSelected] = useState(false);
  const [isButtonSelectedweek, setisButtonSelectedweek] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [weekdates, setweekdates] = useState(getDates(startDate, endDate));
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [apiResponseWeekData, setApiResponse] = useState([]);
  const [prevApiResponse, setPrevApiResponse] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    monthview();
    const fetchqGetLeave = async () => {
      let formateweekdata = "yyyy-MM-dd";
      const reqWeekData = {

        date_in: format(new Date(startDate), formateweekdata),
      };

      try {
        const reqemployeWeekData = await leaveCalenderService.getLeaveWeekView(
          reqWeekData
         
        );
        // console.log("leaveweek response", reqemployeWeekData.message);
        setApiResponse(reqemployeWeekData)
        
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchqGetLeave();

    const fetchreqMonthView = async () => {
      let formateMonthdata = "MM";
   
      const reqMonthView = {
        // month_in: format(new Date(startDate), formateweekdata),
        month_in:format(new Date(startDate), formateMonthdata),
      };

      try {
        const resemployeMonthData = await leaveCalenderService.LeaveMonthView(
          reqMonthView
         
        );
        // console.log("leaveweek response", resemployeMonthData.message);
        setPrevApiResponse(resemployeMonthData)
        
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchreqMonthView();
   
  }, []);
  const monthview = () => {
    setIsButtonSelected(true);
    setisButtonSelectedweek(false);
    setIsVisible(true);
    navigate("/Dashboard/leavecalender/leavecalendermonth");
  };
  const Weekview = () => {
    setIsButtonSelected(false);
    setisButtonSelectedweek(true);
    setIsVisible(false);
    navigate("/Dashboard/leavecalender/leavecalenderweek");
  };

  //PREVE BUTTON--------------------------
  const changeWeekprevHandle = () => {
    setCurrentMonth(subWeeks(currentMonth, 1));
    setCurrentWeek(getWeek(subWeeks(currentMonth, 1)));
    let startDate = startOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 0 });
    let endDate = lastDayOfWeek(subWeeks(currentMonth, 1), { weekStartsOn: 0 });
    setweekdates(getDates(startDate, endDate));
    let formateweekdata = "yyyy-MM-dd";

    const fetchqGetPrevLeave = async () => {
      const reqWeekData = {
        date_in: format(new Date(startDate), formateweekdata),
      };

      try {
        const reqemployeWeekData = await leaveCalenderService.getLeaveWeekView(
          reqWeekData
         
        );
        // console.log("leaveweek response", reqemployeWeekData.message);
        setApiResponse(reqemployeWeekData)
        
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchqGetPrevLeave();
  };
  //NEXT BUTTON
  const changeWeeknextHandle = () => {
    setCurrentMonth(addWeeks(currentMonth, 1));
    setCurrentWeek(getWeek(addWeeks(currentMonth, 1)));
    let startDate = startOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 0 });
    let endDate = lastDayOfWeek(addWeeks(currentMonth, 1), { weekStartsOn: 0 });
    setweekdates(getDates(startDate, endDate));
    let formateweekdata = "yyyy-MM-dd";

    const fetchqGetNextLeave = async () => {
      let reqWeekData = {
        date_in: format(new Date(startDate), formateweekdata),
      };

      try {
        const reqemployeWeekData = await leaveCalenderService.getLeaveWeekView(
          reqWeekData
        );
        setApiResponse(reqemployeWeekData)
        // console.log("leaveweek response", reqemployeWeekData.message);
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchqGetNextLeave();
  };

  const [leaveCurrentMonth, setLeaveCurrentMonth] = useState(new Date());


  const nextMonth = () => {
    setLeaveCurrentMonth(addMonths(leaveCurrentMonth, 1));
    let formateMonthdata = "MM";
    let month=format(addMonths(leaveCurrentMonth, 1), formateMonthdata)

    const fetchreqNextMonthView = async () => {
      const reqMonthView = {
        // month_in: format(new Date(startDate), formateweekdata),
        month_in:month,
      };

      try {
        const resemployeMonthData = await leaveCalenderService.LeaveMonthView(
          reqMonthView
         
        );
        // console.log("leaveweek response", resemployeMonthData.message);
        setPrevApiResponse(resemployeMonthData)
        
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchreqNextMonthView();



  };

  const prevMonth = () => {
    setLeaveCurrentMonth(subMonths(leaveCurrentMonth, 1));
    let formateMonthdata = "MM";
    let month=format(subMonths(leaveCurrentMonth, 1), formateMonthdata)

    const fetchreqPrevMonthView = async () => {
      const reqMonthView = {
        // month_in: format(subMonths(leaveCurrentMonth, 1), formateweekdata),
        month_in:month,
      };

      try {
        const resemployeMonthData = await leaveCalenderService.LeaveMonthView(
          reqMonthView
         
        );
        // console.log("leaveweek response", resemployeMonthData.message);
        setPrevApiResponse(resemployeMonthData)
        
      } catch (error) {
        console.log(error.message);
      }
    };
    fetchreqPrevMonthView();


  };

  const buttonStyleMonth = {
    border: `2px solid ${isButtonSelected ? "" : "#006ECF"}`,
    backgroundColor: isButtonSelected ? "#006ECF" : "white",
    color: isButtonSelected ? "#fff" : "#006ECF",
  };
  const buttonStyleWeek = {
    border: `2px solid ${isButtonSelectedweek ? "" : "#006ECF"}`,
    backgroundColor: isButtonSelectedweek ? "#006ECF" : "white",
    color: isButtonSelectedweek ? "#fff" : "#006ECF",
  };
  return (
    <Fragment>
      <LeaveCalenderheader />
      <div className="row leavecalender ">
        <div className="col-3 mybtns">
          <button
            style={buttonStyleMonth}
            className="d-inline mytimeweek"
            onClick={monthview}
          >
            Month
          </button>
          <button
            className="d-inline mytimeweek mx-2"
            style={buttonStyleWeek}
            onClick={Weekview}
          >
            Week
          </button>{" "}
        </div>
        {isVisible ? (
          <div className="col-4 currentweekstyle">
            <button style={{ cursor: "pointer" }} className="border-0 bg-white">
              <img
                className="hidden  w-auto lg:block CHEVRON"
                onClick={prevMonth}
                src={LEFTCHEVRON}
                alt="Your Company"
              />
            </button>

            <span className="currentweeksubmain">
              <span className="currentweeksub">
                {format(leaveCurrentMonth, "MMMM yyyy")}
              </span>
            </span>
            <button style={{ cursor: "pointer" }} className="border-0 bg-white">
              <img
                className="hidden  w-auto lg:block rightCHEVRON"
                style={{
                  cursor: "pointer",
                }}
                onClick={nextMonth}
                src={RIGHTCHEVRON}
                alt="Your Company"
              />
            </button>
          </div>
        ) : (
          <div className="col-lg-4" >
            <Currentweek
              weekDates={weekdates}
              onWeekPrevChange={changeWeekprevHandle}
              showimageprev={showimagePrev}
              onWeekNextChange={changeWeeknextHandle}
              disabledon={isDisabled}
              DisabledforNext={isDisabledforNext}
              currentweek={currentWeek}
              currentWeekNumber={currentMonth}
              showimageNext={showimageNext}
            />
          </div>
        )}

        <div className="col-4 notestl"> Click on the dates to view details</div>
      </div>

      <Routes>
        <Route exact path="/">
          <Route
            exact
            path="leavecalendermonth"
            element={
              <LeaveCalenderMonthView
                prevApiResponse={prevApiResponse}
                prevMonth={prevMonth}
                leaveCurrentMonth={leaveCurrentMonth}
                setLeaveCurrentMonth={setLeaveCurrentMonth}
              />
            }
          />
          <Route
            exact
            path="leavecalenderweek"
            element={
              <LeaveCalenderWeekView
              apiResponseWeekData={apiResponseWeekData}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                currentMonth={currentMonth}
                weekDates={weekdates}
              />
            }
          />
        </Route>
      </Routes>
    </Fragment>
  );
};

export default Leave_Calender;
