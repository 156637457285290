import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import "../modules/sidenav.css";

import HR from "../assets/images/hr.svg";
import GENERAL_INACTIVE from "../assets/images/general_inactive.svg";
import ADMIN_INACTIVE from "../assets/images/admin_inactive.svg";
import TRAVEL_INACTIVE from "../assets/images/travel_inactive.svg";
import EMP_ENG from "../assets/images/emp_eng_inactive.svg";
import PERF_INACTIVE from "../assets/images/performance_inactive.svg";
import USERICON from "../assets/images/employee_inactive.svg";
import DASHBOARD from "../assets/images/dashboardicon.svg";

const SidebarData = () => {
  return (
    <>
      <div className="dashb">
        <span>
          <img className="dashimg" src={DASHBOARD} alt="Dashboard" />
        </span>
        <span className="dashcont">Dashboard</span>
      </div>
      <Accordion className="bg-transparent border-0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {" "}
            <img className="" src={USERICON} alt="Employee" />
            <span style={{ paddingLeft: "11px" }} className="accheaderbar">
              Employee
            </span>
          </Accordion.Header>
          <Accordion.Body as={Link} to={"/Dashboard/TimeTracking"}>
            Time Tracking
          </Accordion.Body>
          <Accordion.Body as={Link} to={"/Dashboard/Projects"}>
            Projects
          </Accordion.Body>
          <Accordion.Body as={Link} to={"/Dashboard/LeaveMangement"}>
            Leave Mangement
          </Accordion.Body>
          <Accordion.Body as={Link} to={"/Dashboard/EmployeeList"}>
            Employee List
          </Accordion.Body>
          <Accordion.Body as={Link} to={"/Dashboard/Tickets"}>
            Tickets
          </Accordion.Body>
          <Accordion.Body as={Link} to={"/Dashboard/Pending"}>
            Pending
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>
            <img className="" src={HR} alt="HR" />
            <span style={{ paddingLeft: "11px" }} className="accheaderbar">
              {" "}
              HR
            </span>
          </Accordion.Header>
          <Accordion.Body>HR</Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>
            {" "}
            <img className="" src={PERF_INACTIVE} alt="Perfromance" />
            <span style={{ paddingLeft: "11px" }} className="accheaderbar">
              {" "}
              Performance{" "}
            </span>
          </Accordion.Header>
          <Accordion.Body>performance</Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>
            <img className="" src={GENERAL_INACTIVE} alt="General" />
            <span style={{ paddingLeft: "11px" }} className="accheaderbar">
              General{" "}
            </span>
          </Accordion.Header>
          <Accordion.Body>General</Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>
            <img className="" src={EMP_ENG} alt=" Employee Engagement" />
            <span style={{ paddingLeft: "11px" }} className="accheaderbar">
              {" "}
              Employee Engagement{" "}
            </span>
          </Accordion.Header>
          <Accordion.Body as={Link} to={"/Dashboard/IdeaSubmission"}>
            IdeaSubmission
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>
            {" "}
            <img className="" src={ADMIN_INACTIVE} alt="Administration" />
            <span style={{ paddingLeft: "11px" }} className="accheaderbar">
              Administration{" "}
            </span>
          </Accordion.Header>
          <Accordion.Body>Administration</Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>
            <img className="" src={TRAVEL_INACTIVE} alt="TravelDesky" />
            <span style={{ paddingLeft: "11px" }} className="accheaderbar">
              TravelDesk{" "}
            </span>
          </Accordion.Header>
          <Accordion.Body>TravelDesk</Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};

export default SidebarData;
