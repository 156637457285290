import React, { Fragment, useEffect, useState } from 'react'
import "./LeaveBalanceSheet.css";
import LeavemanagementService from "../../services/LeaveManagementServices/LeavecalenderweekService"

export const LeaveBalanceSheet = () => {
    const [leavesavailable, setleavesavailable] = useState();
    let employeeid = sessionStorage.getItem("Emp_id_fetch");

    useEffect(() => {
        const fetchDashboardData = async () => {
           
            const reqbody = {
                emp_id: employeeid,
            };

            try {
                const DashboardDataResp = await LeavemanagementService.DashboardData(
                    JSON.stringify(reqbody)
                );
                setleavesavailable(DashboardDataResp[0][0]);
                console.log(DashboardDataResp.message);
            } catch (error) {
                console.log(error.message);
            }
        };
        fetchDashboardData();


    }, []);

    return (
        <Fragment>
            <div className="d-flex  row card pr-3 pl-3  text-dark h-auto">

                <div >
                    <h5 className="card-title cardhead">Leave Type & Balance</h5>
                    <div className="container matcardleave">
                        <div className="row">
                            <div className="col-sm mb">
                                <p className="cardsubhead">
                                    <span className="matcount1">{leavesavailable?.cntPaidLeavesEligibile - leavesavailable?.cntPaidLeavesTaken}</span>
                                    <span className="matcount2">/{leavesavailable?.cntPaidLeavesEligibile}</span>{" "}
                                </p>
                                <div className="matcont">Paid Leave </div>
                                <div className="available">Available </div>
                            </div>
                            <div className="col-sm mb">
                                <p className="cardsubhead">
                                    <span className="matcount1">{leavesavailable?.cntSickLeavesEligible - leavesavailable?.cntSickLeavesTaken}</span>{" "}
                                    <span className="matcount2">/{leavesavailable?.cntSickLeavesEligible}</span>
                                </p>
                                <div className="matcont">Sick Leave </div>
                                <div className="available">Available </div>
                            </div>
                            <div className="col-sm mb">
                                <p className="cardsubhead">
                                    <span className="matcount1"> {leavesavailable?.cntCompLeavesEligible - leavesavailable?.cntCompLeavesTaken}</span>
                                    <span className="matcount2">/{leavesavailable?.cntCompLeavesEligible}</span>{" "}
                                </p>
                                <div className="matcont">Compensatory </div>
                                <div className="available text-danger">Available </div>
                            </div>
                            <div className="col-sm mb">
                                <p className="cardsubhead">
                                    <span className="matcount1"> {leavesavailable?.cntHolidaysEligible - leavesavailable?.cntHolidayTaken}</span>
                                    <span className="matcount2">/{leavesavailable?.cntHolidaysEligible}</span>
                                </p>
                                <div className="matcont">Public Holidays </div>
                                <button className="btnview">View Details</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </Fragment>
    )
}
